import './App.css';
import LowerNavbar from './layout/LowerNavbar/LowerNavbar';
import Footer from './layout/footer/Footer.js';
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Cotisation from './pages/Cotisation'
import Attestations from './pages/Attestations'
import Documents from './pages/Documents';
import Garde from "./pages/Garde"
import Notifications from './pages/Notifications';
import Profile from './pages/Profile';
import Connexion from './pages/Connexion';
import Inscription from './pages/Inscription'
import { useEffect, useState } from 'react';
import NoUser from './pages/NoUser';
import Offres from './pages/Offres.js';
import SuccessfulPaymentPage from './pages/SuccessPayment.js';
import PaymentFailurePage from "./pages/FailedPayment.js"
import CheckActivite from './pages/CheckActivite.js';
import CryptoJS from 'crypto-js';
import BancairePaiment from './pages/BancairePayment.js';
import MobilePaiment from './pages/Mobile_Paiment.js';
import Recu from './pages/Recu.js';
import OffreDetailes from './pages/OffreDetailes.js';
import AddOffres from './pages/AddOffre.js';
function App() {
  const [render, setRender] = useState(false);
  const [Crypted, setCrypted] = useState(false);

  const decryptText = (encryptedText) => {
    const key = 'your-secret-key';
    const decrypted = CryptoJS.AES.decrypt(encryptedText, key).toString(CryptoJS.enc.Utf8);
    setCrypted(decrypted);
  };

  useEffect(() => {
    var keys = Object.keys(localStorage);
    if (keys.length > 0) {
      var firstKey = keys[0];
      decryptText(firstKey)
    }
  }, [])


  useEffect(() => {
    if (Crypted === "U2FsdGVkX19xqKHwGY8nQLnQc8gwVyHkcrKpJBegXRg") {
      setRender(true);
    }
  }, [Crypted]);


  return (
    <div className='App'>
      <Router>
        <LowerNavbar />

        <div className="pages">
          <Routes>
            <Route path="/" element={<Inscription />} text="Connexion" />
            <Route path="/Connexion" element={<Connexion />} text="Connexion" />
            <Route path="/Cotisation" element={<Cotisation />} text="Cotisation" />
            <Route path="/Profile/:id" element={<Profile />} />

            <Route path="/status_check" element={<SuccessfulPaymentPage />} />
            <Route path="/recu_check" element={<Recu />} />

            <Route path="/status_Bancaire" element={<BancairePaiment />} />
            <Route path="/failed_check" element={<PaymentFailurePage />} />
            
            <Route path="/check" element={<CheckActivite />} />
            <Route path="/Documents" element={<Documents />} /> 
            <Route path="/Attestations" element={<Attestations />} />
            <Route path="/Notifications" element={<Notifications />} />
            {/* <Route path="/Offres" element={<Offres />} />
            <Route path="/Offres/:id" element={<OffreDetailes />} />
            <Route path="/Offres/Ajouter_offre_emploi" element={<AddOffres />} /> */}
            {/* 

            <Route path="/Garde" element={<Garde />} />
          */}

            <Route path="/mobile_check" element={<MobilePaiment />} />
            <Route path="/authRequired" element={<NoUser />} />

          </Routes>
        </div>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
