import React from 'react';
import FrenshLocals from "../assets/locals/fr"
import ArabeLocals from "../assets/locals/arabe"

const modalStyle = {
    position: 'absolute',
    top: '70%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    backgroundColor: '#fff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    zIndex: '999',
    width: '40%',
    borderRadius: '20px',
};

const mobileModalStyle = {
    width: '80%',
};

const scrollListStyle = {
    maxHeight: '300px', // Set your desired max height
    overflowY: 'auto',
};

const listItemStyle = {
    padding: '10px',
    borderBottom: '1px solid #ccc',
    cursor: 'pointer',

};

export default function VilleScroll({ index, onVille }) {

    return (
        <div style={{ ...modalStyle, ...(window.innerWidth <= 600 && mobileModalStyle) }}>
            <div style={scrollListStyle}>
                <ul>
                    {FrenshLocals[index]?.map((e, i) => (
                        <li
                            key={i}
                            style={{ ...listItemStyle }}
                            onClick={() => onVille(e, ArabeLocals[index][i])}
                        >
                            {e}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
