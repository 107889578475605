import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CryptoJS from "crypto-js"

export const getPharmacienCotisation = createAsyncThunk("getPharmacienCotisation", async (id) => {
  try {
    const numConnexion = localStorage.getItem('U2FsdGVkX19xqKHwGY8nQLnQc8gwVyHkcrKpJBegXRg');
    let decryptedBytes = CryptoJS.AES.decrypt(numConnexion, "MOT_DE_KEY");
    let decryptedDataString = decryptedBytes.toString(CryptoJS.enc.Utf8);
    let DecnumConnexion = JSON.parse(decryptedDataString);

    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/getPharmacienCotisation?NumeroInscription=${DecnumConnexion}`);
    return response.data;
  } catch (err) {
    console.log(err)
  }
});

export const PharmacienCotisation = createSlice({
  name: "getPharmacienCotisation",
  initialState: {
    getPharmacienCotisation: null,
    getPharmacienCotisationdata: {
      items: [],
    },
    error: null,
    deleteError: null,
    saveError: null,
    createArticleError: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getPharmacienCotisation.fulfilled, (state, action) => {
      state.getPharmacienCotisationdata.items = action.payload;
    });
  },
});
export default PharmacienCotisation.reducer;