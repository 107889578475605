import React, { useState, useEffect } from 'react'
import '../assets/styles/Profile.css'
import NavigationBtns from '../components/navigationBtns';
import { useSelector, useDispatch } from "react-redux";
import { getPharmacien } from "../redux/getPharmacien"
import { useNavigate } from 'react-router-dom';
import ProfileCarousel from "../layout/ProfileCarousel"
import { useLocation } from "react-router-dom";
import Activite from '../components/Activite';
export default function Profile() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname
  useEffect(() => {
    const numConnexion = localStorage.getItem('U2FsdGVkX19xqKHwGY8nQLnQc8gwVyHkcrKpJBegXRg');
    if (!numConnexion) {
      navigate('/');

    }
  }, [navigate]);
  useEffect(() => {
    dispatch(getPharmacien());
  }, []);

  return (
    <div className='Documents-container'>
      <div className='inner-navbar'>
        <NavigationBtns />
      </div>
      <div className='Profile-body'>
        <div className='Profile-title-section'> <span className='Profile-title'>Profile</span><span className='line'></span></div>
        {path.includes('Profile/activite') ? <Activite /> : <ProfileCarousel />}
      </div>
    </div>
  )
}
