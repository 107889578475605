import React, { useState, useEffect } from 'react';
import axios from 'axios';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    backgroundColor: '#fff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    zIndex: '999',
    width: '40%',
    borderRadius: '20px',
};

const mobileModalStyle = {
    width: '80%',
};

const scrollListStyle = {
    maxHeight: '300px', // Set your desired max height
    overflowY: 'auto',
};

const listItemStyle = {
    padding: '10px',
    borderBottom: '1px solid #ccc',
    cursor: 'pointer',

};

export default function ScrollList({ onData }) {
    let [data, setData] = useState();

    let getActiviteList = () => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/api/getActiviteList`)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    console.log('Response data:', error.response.data);
                    console.log('Response status:', error.response.status);
                } else if (error.request) {
                    console.log('No response received:', error.request);
                } else {
                    console.log('Error:', error.message);
                }
            });
    };

    useEffect(() => {
        getActiviteList();
    }, []);

    return (
        <div style={{ ...modalStyle, ...(window.innerWidth <= 600 && mobileModalStyle) }}>
            <div style={scrollListStyle}>
                <ul>
                    {data?.map((e, i) => (
                        <li
                            key={i}
                            style={{ ...listItemStyle }}
                            onClick={() => onData(e?.LibelleActivite)}
                        >
                            {e?.LibelleActivite}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
