import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CryptoJS from "crypto-js"


export const pharmacienInscription = createAsyncThunk('Inscription', async (data, { rejectWithValue }) => {
    // if (Object.values(data).some(value => !value)) {
    //     const frenchErrorMessage = "Veuillez remplir tous les champs.";
    //     return rejectWithValue(frenchErrorMessage);
    // } else {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/pharmacienRegister`, data);
            let NumeroInscription = JSON.stringify(response.data.pharmacien.NumeroInscription);
            localStorage.setItem('U2FsdGVkX19xqKHwGY8nQLnQc8gwVyHkcrKpJBegXRg', CryptoJS.AES.encrypt(NumeroInscription, "MOT_DE_KEY").toString());
            return response.data.message;
        } catch (err) {
            console.log(err)
            return rejectWithValue(err.response.data.message);
        }
    // }
});


export const InscriptionStore = createSlice({
    name: "Inscription",
    initialState: {
        Inscription: null,
        Inscriptions: {
            items: [],
            count: 0,
        },
        error: null,
        deleteError: null,
        saveError: null,
        createArticleError: null,
    },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(pharmacienInscription.fulfilled, (state, action) => {
            state.Inscriptions.items = action.payload;
        });
    },
});

export default InscriptionStore.reducer;
