import styled from 'styled-components';
import axios from "axios"
import React from 'react';
import { useLocation } from 'react-router-dom';
import 'jspdf-autotable'; // Import the autoTable plugin

export default function Recu() {
  // Hooks
  const location = useLocation();

  // getOrder id
  const searchParams = new URLSearchParams(location.search);
  const NumeroMouvement = searchParams.get('NumeroMouvement');
  const APPROVAL_CODE = searchParams.get('APPROVAL_CODE');

  const Container = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: auto; /* Added margin auto to center horizontally */
    display: flex;
    flex-direction: column; /* Adjusted to a column layout */
    align-items: center;
    justify-content: center;
    margin-top:10rem;
    margin-bottom:20rem
  `;

  const Heading = styled.h1`
    color: #333;
  `;

  const Message = styled.p`
    color: #666;
  `;

  const BackButton = styled.button`
    padding: 10px 20px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px; /* Added margin-top for spacing */
  
    &:hover {
      background-color: #45a049;
    }
  `;


  // download REÇU DE PAIEMENT Pdf
  const downloadPdf = () => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/getPaymentReceipt?ID=${NumeroMouvement}`).then((res) => {
      const blob = new Blob([res.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'REÇU_DE_PAIEMENT.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }).catch((err) => console.log(err))
  };

  return (
    <Container>
      <Heading>{"Paiement réussi !"}</Heading>
      <Message>{<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div>
          Paiement accepté avec succès
        </div>
        <div style={{ fontWeight: 600 }}>
          APPROVAL_CODE: {APPROVAL_CODE}
        </div>
      </div>
      }

      </Message>
      <span style={{ textDecoration: "underLine", cursor: "pointer" }} onClick={downloadPdf}>Télécharger le reçu</span>
      <BackButton onClick={() => window.location.replace('/Cotisation')}>Retour</BackButton>
    </Container>
  )
}
