import React, { useState, useEffect } from 'react'
import '../assets/styles/Notifications.css'
import NavigationBtns from '../components/navigationBtns';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from "crypto-js"

export default function Notifications() {
  let [element, setElement] = useState([])
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [saveId, setSaveId] = useState();
  const handleConfirm = () => {
    deleteNotification()
    setShowModal(false);
  };

  const handleCancel = () => {
    // Your cancel logic goes here
    // For example, you can do nothing or handle cancel action
    // And then close the modal
    setShowModal(false);
  };

  let getPharmacienNotifications = () => {
    const numConnexion = localStorage.getItem('U2FsdGVkX19xqKHwGY8nQLnQc8gwVyHkcrKpJBegXRg');
    let decryptedBytes = CryptoJS.AES.decrypt(numConnexion, "MOT_DE_KEY");
    let decryptedDataString = decryptedBytes.toString(CryptoJS.enc.Utf8);
    let DecnumConnexion = JSON.parse(decryptedDataString);
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/getPharmacienNotification?NumeroInscription=${DecnumConnexion}`).then((res) => setElement(res.data)).catch((err) => console.log(err))
  }
  // Run the function initially

  // Set interval to run the function every 5 minutes (300,000 milliseconds)
  setInterval(getPharmacienNotifications, 300000);

  useEffect(() => {
    getPharmacienNotifications();
    const numConnexion = localStorage.getItem('U2FsdGVkX19xqKHwGY8nQLnQc8gwVyHkcrKpJBegXRg');
    if (!numConnexion) {
      navigate('/authRequired');
    }
  }, [navigate]);

  let deleteNotification = () => {
    let data = { "ID": saveId }
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/deletePharmacienNotification`, data).then(() => getPharmacienNotifications())
  }
  let shoAlertFunction = (id) => {
    setShowModal(true)
    setSaveId(id)
  }

  let saveNotificationsNumber = () => {
    localStorage.setItem("U2dGV19xqGY8nQ8gwVyrgXRg", element.length);
  }
  useEffect(() => {
    saveNotificationsNumber()
  }, [element])

  return (
    <div className='Notifications-container'>
      <div className='inner-navbar'>
        <NavigationBtns />
      </div>
      <div className='Notifications-body'>

        <div className='Notifications-title-section'> <span className='Notifications-title'>Notifications</span><span className='line'></span> </div>

        <div className='Notifications-section'>
          <div className='notification-delete-icon'> </div>
          {
            element.length > 0 ?
              element.map((e, i) => (
                <>
                  <div className='notification-container' style={{ boxShadow: '0 0 10px rgba(83, 83, 83, 0.2)' }}>
                    <div className='notification-header'>
                      <span className='not-title'>CNOPT</span>
                      <span className='line2'></span>
                      <span className='not-date'><RiDeleteBin6Line onClick={() => shoAlertFunction(e.ID)} fontSize={30} color='#03BCCD' /></span>
                    </div>
                    <div className='notification-body'>
                      <span className='not-content'>{e.content}</span>
                    </div>
                  </div >
                  <div className='underline'></div>
                </>
              )) : <div style={{ flex: 5, display: "flex", justifyContent: "center", alignItems: "center", color: "#00B16A", fontWeight: 500,marginTop:"10%" }}>
                <span>Il n'y a pas notifications</span>
              </div>
          }
        </div>
        <div style={{ zIndex: 10 }} className={`confirm-modal ${showModal ? 'show' : ''}`}>
          <div className="modal-content">
            <p>Es-tu sûr</p>
            <p>Vous souhaitez supprimer cette notification ?</p>
            <div className="button-container" >
              <button onClick={handleConfirm}>Oui</button>
              <button onClick={handleCancel}>Non</button>
            </div>
          </div>
          <div className="overlay" onClick={() => setShowModal(false)}></div>
        </div>
      </div>
    </div >
  )
}
