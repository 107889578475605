import React, { useEffect } from 'react'
import { GrSecure } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';

export default function NoUser() {
    const navigate = useNavigate();

    useEffect(() => {
        const numConnexion = localStorage.getItem('U2FsdGVkX19xqKHwGY8nQLnQc8gwVyHkcrKpJBegXRg');
        if (numConnexion) {
            navigate(-1);
        }
    }, [navigate]);

    return (
        <div style={{ display: 'flex', backgroundColor: 'lightgrey', alignItems: "center", justifyContent: "center", flex: 1, height: "40rem", flexDirection: "column" }}>
            <GrSecure size={"15rem"} color='#03BCCD' />
            <span style={{ fontSize: "3rem", color: "white" }}>
                You Need to LogIn first :(
            </span>
        </div>
    )
}
