import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import '../assets/styles/NavigationBtns.css';
import { useLocation } from "react-router-dom";
import { RxPerson } from 'react-icons/rx';
import { GiDiploma } from 'react-icons/gi';
import { IoDocumentsOutline } from 'react-icons/io5';
import { LiaWalletSolid } from 'react-icons/lia';
import { BsShopWindow } from 'react-icons/bs';
import { PiBellThin } from 'react-icons/pi';
import { useSelector } from "react-redux";
import { MdWorkOutline } from "react-icons/md";
import { CiLogout } from "react-icons/ci";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CryptoJS from "crypto-js"
import axios from 'axios'
export default function NavigationBtns() {
    const location = useLocation();
    const path = location.pathname;
    const CodeActivite = useSelector((state) => (state.getPharmacien?.getPharmaciendata?.items?.CodeActivite));
    const [showProfileList, setShowProfileList] = useState(false);
    useEffect(() => {
        if (path == "/Profile/Information%20personnelle" || path == '/Profile/activite') {
            setShowProfileList(true);
        }
    }, [path]);

    const [showModal, setShowModal] = useState(false);
    const [NewNumber, setNewNumber] = useState(0);
    const [OldNumber1, setOldNumber1] = useState(0);
    let getNotificationNumber = async () => {
        try {
            let number = await localStorage.getItem('U2dGV19xqGY8nQ8gwVyrgXRg')
            if (number !== null) { setOldNumber1(JSON.parse(number)) }
        } catch (err) { console.log(err) }
    }
    let getPharmacienNotifications = () => {
        const numConnexion = localStorage.getItem('U2FsdGVkX19xqKHwGY8nQLnQc8gwVyHkcrKpJBegXRg');
        let decryptedBytes = CryptoJS.AES.decrypt(numConnexion, "MOT_DE_KEY");
        let decryptedDataString = decryptedBytes.toString(CryptoJS.enc.Utf8);
        let DecnumConnexion = JSON.parse(decryptedDataString);
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/getPharmacienNotification?NumeroInscription=${DecnumConnexion}`).then((res) => setNewNumber(res.data.length)).catch((err) => console.log(err))
    }
    useEffect(() => {
        getNotificationNumber()
        getPharmacienNotifications()
    }, [navigator])

    const handleConfirm = () => {
        localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE);
        window.location.replace('/');
        setShowModal(false);
    };

    const handleCancel = () => {
        // Your cancel logic goes here
        // For example, you can do nothing or handle cancel action
        // And then close the modal
        setShowModal(false);
    };

    return (
        <>
            <button onClick={() => setShowProfileList(!showProfileList)} activeClassName="active"
                className={showProfileList ? 'navigation-btns' : 'Cotisation-btn'}>
                <RxPerson fontSize={25} />
                <span style={{ paddingLeft: "1rem", width: '50%', textAlign: "left" }}>Profile</span>
            </button>

            {showProfileList && (
                <div className="profile-list">
                    <NavLink
                        exact
                        to="/Profile/Information personnelle"
                        activeClassName="active"
                        className={path.includes('Profile/Information%20personnelle') ? 'mini-navigation-btns' : 'mini-Cotisation-btn'}
                    >
                        <span>Informations personnelles</span>
                    </NavLink>
                    <NavLink
                        exact
                        to="/Profile/activite"
                        activeClassName="active"
                        className={path.includes('Profile/activite') ? 'mini-navigation-btns' : 'mini-Cotisation-btn'}

                    >

                        <span style={{ paddingLeft: "1rem", width: '100%', textAlign: "left" }}>Activité</span>
                    </NavLink>
                </div>
            )}
            <NavLink
                exact
                to="/Attestations"
                activeClassName="active"
                className={path == "/Attestations" ? 'navigation-btns' : 'Cotisation-btn'}
            >
                <GiDiploma fontSize={25} />
                <span style={{ paddingLeft: "1rem", width: '50%' }}>Attestations</span>
            </NavLink>
            <NavLink
                exact
                to="/Documents"
                activeClassName="active"
                className={path == "/Documents" ? 'navigation-btns' : 'Cotisation-btn'}
            >
                <IoDocumentsOutline fontSize={25} />
                <span style={{ paddingLeft: "1rem", width: '50%' }}>Annonce</span>
            </NavLink>
            <NavLink
                exact
                to="/Notifications"
                activeClassName="active"
                className={path == "/Notifications" ? 'navigation-btns' : 'Cotisation-btn'}
            >
                {OldNumber1 < NewNumber ?
                    <div className="red-dot-icon">
                        <PiBellThin fontSize={25} />
                        <div className="red-dot">{NewNumber - OldNumber1}</div>
                    </div> :
                    <PiBellThin fontSize={25} />
                }
                <span style={{ paddingLeft: "1rem", width: '50%' }}>Notifications</span>
            </NavLink>
            <NavLink
                exact
                to="/Cotisation"
                activeClassName="active"
                className={path == "/Cotisation" ? 'navigation-btns' : 'Cotisation-btn'}
            >
                <LiaWalletSolid fontSize={25} />
                <span style={{ paddingLeft: "1rem", width: '50%' }}>Cotisation</span>
            </NavLink>
            {/* <NavLink
                exact
                to="/Offres"
                activeClassName="active"
                className={path == "/Offres" ? 'navigation-btns' : 'Cotisation-btn'}
            >
                <MdWorkOutline fontSize={25} style={{ marginLeft: "10%" }} />
                <span style={{ paddingLeft: "1rem", width: '60%' }}>Offre d’emplois</span>
            </NavLink> */}
            <div
                activeClassName="active"
                className={path == "/Déconnexion" ? 'navigation-btns' : 'Cotisation-btn'}
                style={{ cursor: "pointer" }}
                onClick={() => { setShowModal(true) }}
            >
                <CiLogout fontSize={25} />
                <span style={{ paddingLeft: "1rem", width: '50%' }}>Déconnexion</span>
            </div >
            {/* {CodeActivite ||
                <NavLink
                    exact
                    to="/Garde"
                    activeClassName="active"
                    className={path == "/Garde" ? 'navigation-btns' : 'Cotisation-btn'}
                >
                    <BsShopWindow fontSize={25} />
                    <span style={{ paddingLeft: "1rem", width: '50%' }}>Garde</span>
                </NavLink>}
            <NavLink
                exact
                to="/Offres"
                activeClassName="active"
                className={path == "/Offres" ? 'navigation-btns' : 'Cotisation-btn'}
            >
                <MdWorkOutline fontSize={25} />
                <span style={{ paddingLeft: "1rem", width: '50%' }}>Offres</span>
            </NavLink> */}

            <div style={{ zIndex: 10 }} className={`confirm-modal ${showModal ? 'show' : ''}`}>
                <div className="modal-content">
                    <p>Se déconnecter</p>
                    <p>Êtes vous sûr de vouloir vous déconnecter ?</p>
                    <div className="button-container" >
                        <button onClick={handleConfirm}>Oui</button>
                        <button onClick={handleCancel}>Non</button>
                    </div>
                </div>
                <div className="overlay" onClick={() => setShowModal(false)}></div>
            </div>
        </>
    );
}
