import React, { useState, useEffect } from 'react';
import axios from 'axios';

const modalStyle = {
    position: 'absolute',
    top: '70%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    backgroundColor: '#fff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    zIndex: '999',
    width: '40%',
    borderRadius: '20px',
};

const mobileModalStyle = {
    width: '80%',
};

const scrollListStyle = {
    maxHeight: '300px', // Set your desired max height
    overflowY: 'auto',
};

const listItemStyle = {
    padding: '10px',
    borderBottom: '1px solid #ccc',
    cursor: 'pointer',

};

export default function CountriesScroll({ onCountry }) {
    const tunisianGovernoratesFrensh = [
        "Tunis",
        "Ariana",
        "Ben Arous",
        "Manouba",
        "Nabeul",
        "Zaghouan",
        "Bizerte",
        "Beja",
        "Jendouba",
        "Kef",

        "Siliana",
        "Sousse",
        "Monastir",

        "Mahdia",
        "Sfax",
        "Kairouan",

        "Kasserine",
        "Sidi Bouzid",
        "Gabes",

        "Medenine",
        "Tataouine",
        "Gafsa",
        "Tozeur",
        "Kebili",
    ]


    const tunisianGovernorates = [
        "تونس",
        "أريانة",
        "بن عروس",
        "منوبة",
        "نابل",
        "زغوان",
        "بنزرت",
        "باجة",
        "جندوبة",
        "الكاف",

        "سليانة",
        "سوسة",
        "المنستير",

        "المهدية",
        "صفاقس",
        "القيروان",

        "القصرين",
        "سيدي بوزيد",
        "قابس",

        "مدنين",
        "تطاوين",
        "قفصة",
        "توزر",
        "قبلي",
    ]



    return (
        <div style={{ ...modalStyle, ...(window.innerWidth <= 600 && mobileModalStyle) }}>
            <div style={scrollListStyle}>
                <ul>
                    {tunisianGovernoratesFrensh?.map((e, i) => (
                        <li
                            key={i}
                            style={{ ...listItemStyle }}
                            onClick={() => onCountry(e, tunisianGovernorates[tunisianGovernoratesFrensh.indexOf(e)], i)}
                        >
                            {e}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
