import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { saveAs } from 'file-saver';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


export default async function fetchAndDownloadPDF(id) {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getDocument?ID=${id}`);
    handleDownload(response.data,response.data.name)
  } catch (error) {
    console.error('Error downloading PDF:', error);
    toast.warn(`Erreur Inconnue.`)
  }
}

const handleDownload = (bufferData, fileName) => {
  const uint8Array = new Uint8Array(bufferData.data.data);
  const blob = new Blob([uint8Array], { type: 'application/octet-stream' });
  saveAs(blob, fileName+".pdf");
}

function createAndDownloadPDF(xmlString, fileName) {
  const pdfDefinition = {
    content: [
      // Define the content of your PDF here
      { text: xmlString },
    ],
  };

  // Create a PDF document
  const pdfDoc = pdfMake.createPdf(pdfDefinition);

  // Generate a Blob containing the PDF data
  pdfDoc.getBlob((blob) => {
    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link for downloading
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;

    // Trigger the download
    a.click();

    // Clean up
    URL.revokeObjectURL(url);
  });
}
