import styled from 'styled-components';
import axios from "axios"
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CryptoJS from "crypto-js"
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import the autoTable plugin

const generatePdf = (e, approvalCode, err) => {
  const pdf = new jsPDF();

  // Add title
  pdf.setFontSize(16);
  pdf.text('Récépissé temporaire de paiement', pdf.internal.pageSize.width / 2, 15, 'center');

  // Add table
  const tableData = [
    ['ERROR', err],
    ['ORDER_ID', e],
    ['APPROVAL_CODE', approvalCode],
  ];

  pdf.autoTable({
    startY: 30, // Start the table below the title
    head: [['Key', 'Value']],
    body: tableData,
  });

  return pdf.output('dataurlstring');
};

const Container = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: auto; /* Added margin auto to center horizontally */
  display: flex;
  flex-direction: column; /* Adjusted to a column layout */
  align-items: center;
  justify-content: center;
  margin-top:10rem;
  margin-bottom:20rem
`;



const SuccessfulPaymentPage = () => {

  // Hooks
  const location = useLocation();
  const navigate = useNavigate();

  // getOrder id
  const searchParams = new URLSearchParams(location.search);
  const OrderId = searchParams.get('orderId');

  // states
  let [data, setData] = useState()
  let [Error, setError] = useState()


  // remove orderId from url Parameter
  const removeQueryParameter = () => {
    const { search, pathname } = location;
    const searchParams = new URLSearchParams(search);
    searchParams.delete('orderId');
    const newSearch = searchParams.toString() ? `?${searchParams.toString()}` : '';
    navigate(`${pathname}${newSearch}`, { replace: true });
  };

  let checkPayment = () => {
    axios
      .get(`${process.env.REACT_APP_CLICKTOPAY}/payment/rest/getOrderStatus.do`, {
        params: {
          language: 'en',
          orderId: OrderId,
          password: process.env.REACT_APP_PASSWORD,
          userName: process.env.REACT_APP_USERNAME,
        },
      }).then((res) => {

        setData(res.data)

        try {

          const numConnexion = localStorage.getItem('U2FsdGVkX19xqKHwGY8nQLnQc8gwVyHkcrKpJBegXRg');
          let decryptedBytes = CryptoJS.AES.decrypt(numConnexion, "MOT_DE_KEY");
          let decryptedDataString = decryptedBytes.toString(CryptoJS.enc.Utf8);
          let DecnumConnexion = JSON.parse(decryptedDataString);

          let paymentAmount = res.data?.Amount;

          if (paymentAmount !== undefined) {
            let requestData = {
              "NumeroInscription": DecnumConnexion,
              "Type": 'Cotisation',
              "ModePaiement": "ClickToPay",
              "Montant": paymentAmount / 1000,
              "OrderId": OrderId,
            };

            axios.post(`${process.env.REACT_APP_BASE_URL}/api/addTransaction`, requestData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((res) => {

                try {
                  // localStorage.setItem("ORDER_ID", OrderId);
                  window.location.replace(`/recu_check?APPROVAL_CODE=${data?.approvalCode}&NumeroMouvement=${res?.data?.NumeroMouvement}`)
                } catch (err) { console.log(err, "err") }

              })
              .catch((error) => {
                setError(error)
                let requestDataString = JSON.stringify(requestData);
                let encryptedData = CryptoJS.AES.encrypt(requestDataString, "IN_CASE_ERROR").toString();
                localStorage.setItem("IN_CASE_ERROR", encryptedData);
                let e = error;
                if (error.response) {
                  e = error.response.data;
                  if (error.response.data && error.response.data.error) {
                    e = error.response.data.error;
                  } else {
                    e = error.response.data.message;
                  }
                } else if (error.message) {
                  e = error.message;
                } else {
                  e = "Unknown error";
                }
                console.log(e);
              });
          }
        } catch (err) { console.log(err) }
      }
      )
      .catch((err) => console.log(err, "err"))
  }

  useEffect(() => {
    checkPayment()
  }, [])

  // generate Récépissé temporaire de paiement pdf 
  const onButtonClick = (e, approvalCode, err) => {
    const pdfDataUrl = generatePdf(e, approvalCode, err);
    const link = document.createElement('a');
    link.href = pdfDataUrl;
    link.download = 'Récépissé_temporaire_de_paiement.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    removeQueryParameter()

  };


  return (
    <Container>
      {
        Error ?
          <div>
            {`Nous nous excusons pour toute perturbation. Une situation inattendue s'est produite, mais nous travaillons à la résoudre. Si vous avez des inquiétudes, n'hésitez pas à nous contacter. Votre satisfaction est notre priorité, et nous sommes déterminés à résoudre ce problème.`}
            <br />
            <div style={{ fontWeight: 600, width: "100%", fontSize: "90%" }}>
              ORDER_ID : {OrderId}
            </div>
            <div style={{ fontWeight: 600, width: "100%", fontSize: "90%" }}>

              APPROVAL_CODE : {data?.approvalCode}
            </div>
            <button onClick={() => onButtonClick(OrderId, data?.approvalCode, Error)}>
              Imprimé le code
            </button>
            <button style={{ backgroundColor: "InactiveCaption", margin: 10 }} onClick={() => window.location.replace('/Cotisation')}>Retour</button>
          </div>
          : null
      }
    </Container>
  );
};

export default SuccessfulPaymentPage;
