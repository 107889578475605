import React, { useState, useEffect } from 'react'
import '../assets/styles/Attestations.css'
import NavigationBtns from '../components/navigationBtns';
import DownloadPdf from '../apis/DownloadPdf'
import DemandeAttestaion from "../apis/DemandeAttestaion"
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import CryptoJS from "crypto-js"
import { useSelector } from "react-redux";

export default function Attestations() {
  const [loading, setLoading] = useState({
    downloadButton1: false,
    downloadButton2: false,
    downloadButton3: false,
    downloadButton4: false,
    downloadButton5: false,
    downloadButton6: false,
    downloadButton7: false,
    downloadButton8: false,
  });
  const [numConnexion, setnumConnexion] = useState()
  const CodeActivite = useSelector((state) => (state.getPharmacien?.getPharmaciendata?.items?.Activite?.CodeActivite));

  const navigate = useNavigate();
  useEffect(() => {
    const numConnexion = localStorage.getItem('U2FsdGVkX19xqKHwGY8nQLnQc8gwVyHkcrKpJBegXRg');
    let decryptedBytes = CryptoJS.AES.decrypt(numConnexion, "MOT_DE_KEY");
    let decryptedDataString = decryptedBytes.toString(CryptoJS.enc.Utf8);
    let DecnumConnexion = JSON.parse(decryptedDataString);
    setnumConnexion(DecnumConnexion)
    if (!DecnumConnexion) {
      navigate('/authRequired');

    }
  }, [navigate]);

  const startDownloading = async (type, year, num) => {
    setLoading({ ...loading, [`${num}`]: true });
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/getAttestation?code=${type}&NumeroInscription=${numConnexion}&Date=${year}-01-01`);
      const blob = await response.blob();
      if (blob.type.split('/')[1] === "pdf") {

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Attestation d'${type}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setLoading({ ...loading, [`${num}`]: false });
      } else {
        toast.warn(`Le paiement de la cotisation de ${year} n'a pas été effectué.`)
        setLoading({ ...loading, [`${num}`]: false });
      }

    } catch (error) {
      setLoading({ ...loading, [`${num}`]: false });

      console.error('Error downloading PDF:', error);
    };


  };

  const startDownloadingar = async (type, year, num) => {
    setLoading({ ...loading, [`${num}`]: true });
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/getAttestation?code=${type + "_ar"}&NumeroInscription=${numConnexion}&Date=${year}-01-01`);
      const blob = await response.blob();

      if (blob.type.split('/')[1] === "pdf") {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Attestation d'${type}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setLoading({ ...loading, [`${num}`]: false });
      } else {
        toast.warn(`Le paiement de la cotisation de ${year} n'a pas été effectué.`)
        setLoading({ ...loading, [`${num}`]: false });
      }

    } catch (error) {
      setLoading({ ...loading, [`${num}`]: false });

      console.error('Error downloading PDF:', error);
    };


  };
  return (
    <div className='Attestations-container'>
      <div className='inner-navbar'>
        <NavigationBtns />
        <ToastContainer />

      </div>
      <div className='Attestations-body'>
        <div className='Attestations-title-section'> <span className='Attestations-title'>Attestations</span><span className='line'></span> </div>
        <div className='section-one-Attestations'>


          {
            CodeActivite !== "S.A" && <div className='AttestationExercice'>
              <div className='Attestation-title-section'><span className='attestation-text'>Attestation d’exercice</span></div>
              <div className='download-section'>
              </div>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", width: "100%", flex: 0.8, }}>

                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>

                  <div style={{ backgroundColor: "#03BCCD", borderRadius: "50px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {
                      loading.downloadButton1 ? <TailSpin color="white" height='25px' strokeWidth='3px' radius={"1px"} /> :
                        <button style={{ backgroundColor: 'transparent', border: "none", fontSize: "13px", color: "white" }} onClick={() => { startDownloading('exercice', new Date().getFullYear() - 1, "downloadButton1") }}>Télécharger FR ({new Date().getFullYear() - 1})</button>
                    }
                  </div>
                  <div style={{ backgroundColor: "#03BCCD", width: "100%", borderRadius: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {
                      loading.downloadButton2 ? <TailSpin color="white" height='25px' strokeWidth='3px' radius={"1px"} /> :
                        <button style={{ backgroundColor: 'transparent', border: "none", fontSize: "13px", color: "white" }} onClick={() => { startDownloading('exercice', new Date().getFullYear(), "downloadButton2") }}>Télécharger FR ({new Date().getFullYear()})</button>
                    }
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>

                  <div style={{ backgroundColor: "#03BCCD", color: "white", borderRadius: "50px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {
                      loading.downloadButton3 ? <TailSpin color="white" height='25px' strokeWidth='3px' radius={"1px"} /> :
                        <button style={{ backgroundColor: 'transparent', border: "none", fontSize: "13px", color: "white" }} onClick={() => { startDownloadingar('exercice', new Date().getFullYear() - 1, "downloadButton3") }}>Télécharger AR ({new Date().getFullYear() - 1})</button>
                    }
                  </div>
                  <div style={{ backgroundColor: "#03BCCD", width: "100%", borderRadius: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {
                      loading.downloadButton4 ? <TailSpin color="white" height='25px' strokeWidth='3px' radius={"1px"} /> :
                        <button style={{ backgroundColor: 'transparent', border: "none", fontSize: "13px", color: "white" }} onClick={() => { startDownloadingar('exercice', new Date().getFullYear(), "downloadButton4") }}>Télécharger AR ({new Date().getFullYear()})</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          }

          <div className='AttestationExercice'>
            <div className='Attestation-title-section'><span className='attestation-text'>Attestation d’inscription</span></div>
            <div className='download-section'>
            </div>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", width: "100%", flex: 0.8 }}>

              <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>

                <div style={{ backgroundColor: "#03BCCD", color: "white", borderRadius: "50px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {
                    loading.downloadButton5 ? <TailSpin color="white" height='25px' strokeWidth='3px' radius={"1px"} /> :
                      <button style={{ backgroundColor: 'transparent', border: "none", fontSize: "13px", color: "white" }} onClick={() => { startDownloading('inscription', new Date().getFullYear() - 1, "downloadButton5") }}>Télécharger FR ({new Date().getFullYear() - 1})</button>
                  }
                </div>
                <div style={{ backgroundColor: "#03BCCD", color: "white", borderRadius: "50px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {
                    loading.downloadButton6 ? <TailSpin color="white" height='35px' width={"90px"} strokeWidth='3px' radius={"1px"} /> :
                      <button style={{ backgroundColor: 'transparent', border: "none", fontSize: "13px", color: "white" }} onClick={() => { startDownloading('inscription', new Date().getFullYear(), "downloadButton6") }}>Télécharger FR ({new Date().getFullYear()})</button>
                  }
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>

                <div style={{ backgroundColor: "#03BCCD", color: "white", borderRadius: "50px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {
                    loading.downloadButton7 ? <TailSpin color="white" height='25px' strokeWidth='3px' radius={"1px"} /> :
                      <button style={{ backgroundColor: 'transparent', border: "none", fontSize: "13px", color: "white" }} onClick={() => { startDownloadingar('inscription', new Date().getFullYear() - 1, "downloadButton7") }}>Télécharger AR ({new Date().getFullYear() - 1})</button>
                  }
                </div>
                <div style={{ backgroundColor: "#03BCCD", color: "white", width: "100%", borderRadius: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {
                    loading.downloadButton8 ? <TailSpin color="white" height='25px' strokeWidth='3px' radius={"1px"} /> :
                      <button style={{ backgroundColor: 'transparent', border: "none", fontSize: "13px", color: "white" }} onClick={() => { startDownloadingar('inscription', new Date().getFullYear(), "downloadButton8") }}>Télécharger AR ({new Date().getFullYear()})</button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='underLine'></div>
        {/* <div className='section-two-Attestations'>
          <div className='demande-title'> <span>Attestation de bonne conduite</span></div>
          <div className='demande-btn'><button style={{ backgroundColor: 'transparent', border: "none", fontSize:"13px",color:"white", color: "white" }} onClick={DemandeAttestaion}>Demande d'attestation</button></div>
        </div> */}
      </div>
    </div>
  )
}
