import styled from 'styled-components';
import axios from "axios"
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TailSpin } from "react-loader-spinner";
import CryptoJS from "crypto-js"
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import the autoTable plugin

const generatePdf = (err) => {
    const pdf = new jsPDF();

    // Add title
    pdf.setFontSize(16);
    pdf.text('Récépissé temporaire de paiement', pdf.internal.pageSize.width / 2, 15, 'center');

    // Add table
    const tableData = [
        ['ERROR', err],
    ];

    pdf.autoTable({
        startY: 30, // Start the table below the title
        head: [['Key', 'Value']],
        body: tableData,
    });

    return pdf.output('dataurlstring');
};
const Container = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: auto; /* Added margin auto to center horizontally */
  display: flex;
  flex-direction: column; /* Adjusted to a column layout */
  align-items: center;
  justify-content: center;
  margin-top:10rem;
  margin-bottom:20rem
`;

const SuccessIcon = styled.div`
  color: #4CAF50;
  font-size: 80px;
  margin-bottom: 20px;
`;

const Heading = styled.h2`
  color: #333;
`;

const Message = styled.p`
  color: #666;
  text-align:center
`;
const MessageTWO = styled.p`
  color: #666;
  font-size:13px
`;


const BackButton = styled.button`
  padding: 10px 20px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px; /* Added margin-top for spacing */

  &:hover {
    background-color: #45a049;
  }
`;

const BancairePaiment = () => {
    const location = useLocation();
    let [data, setData] = useState()
    let [NumeroMouvement, setNumeroMouvement] = useState()
    let [Error, setError] = useState()
    const downloadPdf = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/getPaymentReceipt?ID=${data?.NumeroMouvement}`).then((res) => {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'REÇU_DE_PAIEMENT.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }).catch((err) => console.log(err))
    };

    let sendData = () => {
        let paymentAmount = window.location.href.split('data=')[1];
        let decryptedBytes = CryptoJS.AES.decrypt(paymentAmount, "DATA");
        let decryptedDataString = decryptedBytes.toString(CryptoJS.enc.Utf8);
        let DecnumConnexion = JSON.parse(decryptedDataString);
        setData(DecnumConnexion)

    };

    // useEffect(() => {
    //     sendData();
    // }, []);

    const onButtonClick = (err) => {
        const pdfDataUrl = generatePdf(err);
        const link = document.createElement('a');
        link.href = pdfDataUrl;
        link.download = 'Récépissé_temporaire_de_paiement.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <Container>
            {!Error ?
                <>
                    {!data ?
                        <>
                            <Heading>{"Demande ajoutée avec succès"}</Heading>
                            <Message>
                                Nous vous informerons une fois que nous l'aurons vérifié

                            </Message>
                            {
                                data && <span style={{ textDecoration: "underLine", cursor: "pointer" }} onClick={downloadPdf}>Télécharger le reçu</span>}
                            <BackButton onClick={() => window.location.replace('/Cotisation')}>Retour</BackButton>
                        </> :
                        <TailSpin color="grey" height='100%' width="100%" strokeWidth='1px' radius={"0.1px"} />
                    }
                </> : <div>
                    {`Nous nous excusons pour toute perturbation. Une situation inattendue s'est produite, mais nous travaillons à la résoudre. Si vous avez des inquiétudes, n'hésitez pas à nous contacter. Votre satisfaction est notre priorité, et nous sommes déterminés à résoudre ce problème.`}
                    <br />
                    <button onClick={() => onButtonClick(Error)}>
                        Imprimé le code
                    </button>
                    <button style={{ backgroundColor: "InactiveCaption", margin: 10 }} onClick={() => window.location.replace('/Cotisation')}>Retour</button>
                </div>}

        </Container>
    );
};

export default BancairePaiment;
