import axios from 'axios'
import CryptoJS from "crypto-js"

const DemandeAttestaion = async (type) => {
    try {
        const numConnexion = localStorage.getItem('U2FsdGVkX19xqKHwGY8nQLnQc8gwVyHkcrKpJBegXRg');
        let decryptedBytes = CryptoJS.AES.decrypt(numConnexion, "MOT_DE_KEY");
        let decryptedDataString = decryptedBytes.toString(CryptoJS.enc.Utf8);
        let DecnumConnexion = JSON.parse(decryptedDataString);
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/addDemandeAttestation?NumeroInscription=${DecnumConnexion}`).then(async (response) => {
            alert(response.data.message);
        }).catch(async (error) => {
            if (error.response) {
                console.log('Response data:', error.response.data);
                alert(error.response.data.message)
                console.log('Response status:', error.response.status);
            } else if (error.request) {
                console.log('No response received:', error.request);
            } else {
                console.log('Error:', error.message);
            }
        })
    } catch (error) {
        console.error('Download error:', error);
    }
};

export default DemandeAttestaion