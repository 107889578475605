import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./LowerNavbar.css";
import { HamburgetMenuClose, HamburgetMenuOpen } from "../../assets/Icons";
import logo from "../../assets/logo (2).png"
import { PiBellThin } from 'react-icons/pi';
import { HiArrowLongLeft } from 'react-icons/hi2';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { LiaWalletSolid } from 'react-icons/lia';
import { GiDiploma } from 'react-icons/gi';
import { IoDocumentsOutline } from 'react-icons/io5';
import { BsShopWindow } from 'react-icons/bs';
import { RxPerson } from 'react-icons/rx';
import { CiLogout } from "react-icons/ci";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import CryptoJS from "crypto-js"
import axios from 'axios'

function LowerNavbar() {
    const [click, setClick] = useState(false);
    const [render, setRender] = useState(false);
    const [clickHiden, setClickHiden] = useState(false);
    const Prenom = useSelector((state) => (state.getPharmacien?.getPharmaciendata?.items?.Prenom))
    const Nom = useSelector((state) => (state.getPharmacien?.getPharmaciendata?.items?.Nom))
    const Photo = useSelector((state) => (state.getPharmacien.getPharmaciendata?.items?.Photo?.data?.data))
    const CodeAppellation = useSelector((state) => (state.getPharmacien?.getPharmaciendata?.items?.CodeAppellation))
    const location = useLocation();
    const path = location.pathname;
    const CodeActivite = useSelector((state) => (state.getPharmacien?.getPharmaciendata?.items?.CodeActivite))
    const [showModal, setShowModal] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [NewNumber, setNewNumber] = useState(0);
    const [OldNumber1, setOldNumber1] = useState(0);
    let getNotificationNumber = async () => {
        try {
            let number = await localStorage.getItem('U2dGV19xqGY8nQ8gwVyrgXRg')
            if (number !== null) { setOldNumber1(JSON.parse(number)) }
        } catch (err) { console.log(err) }
    }
    let getPharmacienNotifications = () => {
        const numConnexion = localStorage.getItem('U2FsdGVkX19xqKHwGY8nQLnQc8gwVyHkcrKpJBegXRg');
        if (numConnexion !== null) {
            let decryptedBytes = CryptoJS.AES.decrypt(numConnexion, "MOT_DE_KEY");
            let decryptedDataString = decryptedBytes.toString(CryptoJS.enc.Utf8);
            let DecnumConnexion = JSON.parse(decryptedDataString);
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/getPharmacienNotification?NumeroInscription=${DecnumConnexion}`).then((res) => setNewNumber(res.data.length)).catch((err) => console.log(err))
        }
    }
    useEffect(() => {
        getNotificationNumber()
        getPharmacienNotifications()
    }, [])

    let getPhoto = () => {
        if (Photo) {

            const uint8Array = new Uint8Array(Photo);
            const binaryString = uint8Array.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
            const base64String = btoa(binaryString);
            const bufferDataUrl = `data:image/png;base64,${base64String}`;
            setImageSrc(bufferDataUrl);
        }
    }

    useEffect(() => {
        getPhoto()
    }, [Photo])
    const handleConfirm = () => {
        localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE);
        window.location.replace('/');
        setShowModal(false);
    };

    const handleCancel = () => {
        // Your cancel logic goes here
        // For example, you can do nothing or handle cancel action
        // And then close the modal
        setShowModal(false);
    };

    useEffect(() => {
        const numConnexion = localStorage.getItem('U2FsdGVkX19xqKHwGY8nQLnQc8gwVyHkcrKpJBegXRg');
        if (numConnexion) {
            setRender(true);
        }
    }, []);

    const handleClick = () => setClick(!click);
    const handleClickHiden = () => {
        setClick(!click);
        setClickHiden(!clickHiden);
    };

    return (
        <>
            <nav className="navbar">
                <nav className='UpperNavbarcontainer'>
                    <div className='UpperNavbarcontent'>
                        <div className='UpperNavbarcontact'>
                            <span className='UpperNavbartext' >
                                +(216) 71 79 57 22
                            </span>
                            <span className='UpperNavbartext'>
                                info@cnopt.tn
                            </span>
                        </div>
                        <div className="savespace"></div>
                        <div className='UpperNavbarlinks'>



                            <span className='UpperNavbartext' style={{ color: "#00B16A" }}>
                                Suivez-Nous
                            </span>
                            <div className="icons-container">

                                <span className='UpperNavbartext'>
                                    <a href="https://www.linkedin.com/company/cnopt/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30%" height="30%" viewBox="0 0 30 30">
                                            <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z" fill="#FFFFFF"></path>
                                        </svg>
                                    </a>
                                </span>

                                <span className='UpperNavbartext'>
                                    <a href="https://www.instagram.com/cnopt.tn?igsh=MXhrZ3I2aWQyaDVpbQ==" target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30%" height="30%" viewBox="0 0 50 50">
                                            <path d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z" fill="#FFFFFF"></path>
                                        </svg>
                                    </a>
                                </span>

                                <span className='UpperNavbartext'>
                                    <a href="https://www.facebook.com/CNOPT.tn" target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30%" height="30%" viewBox="0 0 48 48">
                                            <path fill="rgba(255, 255, 255, 0)" d="M0 0h24v24H0z" />
                                            <path fill="#00B16A" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"></path>
                                            <path fill="#fff" d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"></path>
                                        </svg>
                                    </a>
                                </span>


                            </div>

                        </div>
                    </div>
                </nav>


                <div className="nav-container">
                    <div className="nav-btn">
                        {/* <button className="nav-button" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><HiArrowLongLeft style={{ marginRight: "0.5rem" }} /> Retour à la page d'accueil</button> */}
                    </div>

                    <div exact to="/Connexion" className="nav-logo" >
                        <img src={logo} alt="logo" className="nav-logo-img" />
                    </div>
                    <ul className={"nav-menu-third"}>

                        {Nom && <> <li className="nav-item">
                            <NavLink
                                exact
                                to="/Profile/Information personnelle"
                                activeClassName="active"
                                className="nav-links-name"
                                onClick={handleClick}
                                style={{ color: '#00B16A' }}
                            >
                                {Prenom && Prenom + " " + Nom}
                            </NavLink>
                        </li>

                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/Profile/Information personnelle"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    {imageSrc ? <img src={imageSrc} style={{ height: "3rem", border: "3px solid white", width: "3rem", borderRadius: "0.5rem", resize: "contain" }} className="shadowed-image" // Apply the shadowed-image class
                                        alt="logo" /> :

                                        <img src={"https://cdn-icons-png.flaticon.com/512/8742/8742495.png"} style={{ height: "3rem", border: "3px solid white", width: "3rem", borderRadius: "0.5rem", resize: "contain" }} className="shadowed-image" // Apply the shadowed-image class
                                            alt="logo" />
                                    }
                                    <div className="hiden-menu">
                                        <span className="icon">Profile</span>
                                    </div>
                                </NavLink>
                            </li>


                            {/* <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/Notifications"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >

                                    <>
                                        <PiBellThin fontSize={25} />
                                        <div className="hiden-menu">
                                            <span className="icon">Notifications</span>
                                        </div>
                                    </>
                                </NavLink>
                            </li> */}
                        </>}
                    </ul>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>

                        {/* {!clickHiden && <li className="nav-item">
                            <NavLink
                                exact
                                to="/"
                                activeClassName="active"
                                className="nav-links"
                                onClick={handleClick}
                                style={{ color: '#00B16A' }}
                            >
                                {Prenom && Prenom + " " + Nom}
                            </NavLink>
                        </li>}
                        {Nom && !clickHiden &&
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/about"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >

                                    <img src={'https://www.svgrepo.com//show/5125/avatar.svg'} style={{ height: "3rem", border: "3px solid white", width: "3rem", borderRadius: "0.5rem", resize: "contain" }} className="shadowed-image" // Apply the shadowed-image class
                                        alt="logo" />
                                    <div className="hiden-menu">
                                        <span className="icon">Profile</span>
                                    </div>
                                </NavLink>
                            </li>
                        }

                        {!clickHiden && <li className="nav-item">
                            <NavLink
                                exact
                                to="/blog"
                                activeClassName="active"
                                className="nav-links"
                                onClick={handleClick}
                            >

                                <>
                                    {Nom &&
                                        <PiBellThin fontSize={25} />
                                    }
                                    <div className="hiden-menu">
                                        <span className="icon">Notifications</span>
                                    </div>
                                </>
                            </NavLink>
                        </li>} */}
                    </ul>


                    {render && <div className="nav-icon" onClick={handleClickHiden}>
                        {!click ? (
                            <span className="icon">
                                <HamburgetMenuOpen />{" "}
                            </span>
                        ) : (
                            <span className="icon">
                                <HamburgetMenuClose />
                            </span>
                        )}
                    </div>}
                </div>
            </nav>
            {clickHiden && <div style={{ position: "absolute", right: "0px" }}>

                <Sidebar backgroundColor="white" height="100%" >
                    <Menu>
                        <SubMenu label="Profile">
                            <MenuItem onClick={() => window.location.replace('/Profile/Information personnelle')}> Information personnelle </MenuItem>
                            <MenuItem onClick={() => window.location.replace('/Profile/activite')}> Activite </MenuItem>
                        </SubMenu>
                        <MenuItem onClick={() => window.location.replace('/cotisation')}> Cotisation </MenuItem>
                        <MenuItem onClick={() => window.location.replace('/Documents')}> Annonce </MenuItem>
                        <MenuItem onClick={() => window.location.replace('/Attestations')}> Attestations </MenuItem>
                        <MenuItem onClick={() => window.location.replace('/Notifications')}> {OldNumber1 < NewNumber ? <div className="red-dot">{NewNumber - OldNumber1}</div> : null}
                            Notifications </MenuItem>
                        {/* <MenuItem onClick={() => window.location.replace('/Offres')}> Offre d’emplois </MenuItem> */}
                        <MenuItem onClick={() => setShowModal(true)}> Déconnexion </MenuItem>

                    </Menu>
                </Sidebar>
            </div>}
            <div style={{ zIndex: 10 }} className={`confirm-modal ${showModal ? 'show' : ''}`}>
                <div className="modal-content">
                    <p>Se déconnecter</p>
                    <p>Êtes vous sûr de vouloir vous déconnecter ?</p>
                    <div className="button-container">
                        <button onClick={handleConfirm}>Oui</button>
                        <button onClick={handleCancel}>No</button>
                    </div>
                </div>
                <div className="overlay" onClick={() => setShowModal(false)}></div>
            </div>
        </>
    );
}

export default LowerNavbar;
