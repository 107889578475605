import styled from 'styled-components';
import React  from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Container = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: auto; /* Added margin auto to center horizontally */
  display: flex;
  flex-direction: column; /* Adjusted to a column layout */
  align-items: center;
  justify-content: center;
  margin-top:10rem;
  margin-bottom:20rem
`;



const Heading = styled.h1`
  color: #333;
  `;

const Message = styled.p`
  color: #666;
  font-size: 35px;

`;


const FailedPaiment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const OrderId = searchParams.get('orderId');

  return (
    <Container>
      <Heading>Désole</Heading>
      <Message>Paiement refusé</Message>
      <div style={{ fontWeight: 600, fontSize: 12 }}>
        ORDER_ID: {OrderId}
      </div>
    </Container>
  );
};

export default FailedPaiment;
