const Arabe_locals = [
    [
        "تونس العاصمة",
        "باردو",
        "الكرم",
        "حلق الوادي",
        "قرطاج",
        "سيدي بوسعيد",
        "المرسى",
        "سيدي حسين"
    ],
    [
        "أريانة",
        "سكرة",
        "رواد",
        "قلعة الأندلس",
        "سيدي ثابت",
        "التضامن",
        "المنيهلة"
    ],
    [
        "بن عروس",
        "المروج",
        "حمام الأنف",
        "حمام الشط",
        "بومهل البساتين",
        "الزهراء",
        "رادس",
        "مقرين",
        "المحمدية",
        "مرناق",
        "الخليدية",
        "فوشانة",
        "نعسان"
    ],
    [
        "منوبة",
        "الدندان",
        "دوار هيشر",
        "وادي الليل",
        "المرناقية",
        "برج العامري",
        "الجديدة",
        "طبربة",
        "البطان",
        "البساتين"
    ],
    [
        "نابل",
        "دار شعبان الفهري",
        "بني خيار",
        "المعمورة",
        "الصمعة",
        "قربة",
        "تازركة",
        "منزل تميم",
        "منزل حر",
        "الميدة",
        "قليبية",
        "أزمور",
        "حمام الأغزاز",
        "دار علوش",
        "الهوارية",
        "تاكلسة",
        "سليمان",
        "قربص",
        "منزل بوزلفة",
        "بني خلاد",
        "زاوية الجديدي",
        "قرمبالية",
        "بوعرقوب",
        "الحمامات",
        "فندق الجديد - سلتان",
        "تزغران بوكريم زاوية المقايز",
        "سيدي الجديدي",
        "الشريفات بوشراي"
    ],
    [
        "زغوان",
        "الزريبة",
        "بئر مشارقة",
        "جبل الوسط",
        "الفحص",
        "الناظور",
        "صواف",
        "العمايم"
    ],
    [
        "بنزرت",
        "سجنان",
        "ماطر",
        "منزل بورقيبة",
        "تينجة",
        "غار الملح",
        "عوسجة",
        "منزل جميل",
        "منزل عبد الرحمان",
        "العالية",
        "راس الجبل",
        "الماتلين",
        "رفراف",
        "أوتيك",
        "جومين",
        "غزالة",
        "الحشاشنة"
    ],
    [
        "باجة",
        "المعقولة",
        "زهرة مدين",
        "نفزة",
        "تبرسق",
        "تستور",
        "قبلاط",
        "مجاز الباب",
        "تيبار",
        "وشتاتة الجميلة",
        "سيدي إسماعيل",
        "السلوقية"
    ],
    [
        "جندوبة",
        "بوسالم",
        "طبرقة",
        "عين دراهم",
        "فرنانة",
        "بني مطير",
        "غار الدماء",
        "وادي مليز",
        "بلطة بوعوان",
        "سوق السبت",
        "القلعة - المعدن - فرقصان",
        "الجواودة",
        "طبرقة الجديدة",
        "حمام بورقيبة"
    ],
    [
        "الكاف",
        "نبر",
        "الطويرف",
        "ساقية سيدي يوسف",
        "تاجروين",
        "منزل سالم",
        "قلعة السنان",
        "القلعة الخصباء",
        "الجريصة",
        "القصور",
        "الدهماني",
        "السرس",
        "الزعفران - دير الكاف",
        "بهرة",
        "المرجى"
    ],
    [
        "سليانة",
        "بوعرادة",
        "قعفور",
        "الكريب",
        "سيدي بورويس",
        "مكثر",
        "الروحية",
        "كسرى",
        "برقو",
        "العروسة",
        "سيدي مرشد",
        "الحبابسة"
    ],
    [
        "سوسة",
        "قصيبة سوسة والثريات",
        "الزهور - سوسة",
        "زاوية سوسة",
        "حمام سوسة",
        "أكودة",
        "القلعة الكبرى",
        "سيدي بوعلي",
        "هرقلة",
        "النفيضة",
        "بوفيشة",
        "سيدي الهاني",
        "مساكن",
        "القلعة الصغرى",
        "المسعدين",
        "كندار",
        "القريمات هيشر",
        "شط مريم"
    ],
    [
        "المنستير",
        "خنيس",
        "الوردانين",
        "الساحلين معتمر",
        "سيدي عامر مسجد عيسى",
        "زرمدين",
        "بني حسان",
        "غنادة",
        "جمال",
        "منزل كامل",
        "زاوية قنطش",
        "بنبلة المنارة",
        "منزل نور",
        "المصدور منزل حرب",
        "مكنين",
        "سيدي بنور",
        "منزل فارسي",
        "عميرة الفحول",
        "عميرة التوازرة",
        "عميرة الحجاج",
        "الشراحيل",
        "البقالطة",
        "طبلبة",
        "قصر هلال",
        "قصيبة المديوني",
        "بنان بوضر",
        "طوزة",
        "صيادة",
        "لمطة",
        "بوحجر",
        "منزل حياة"
    ],
    [
        "المهدية",
        "رجيش",
        "بومرداس",
        "أولاد الشامخ",
        "شربان",
        "هبيرة",
        "السواسي",
        "الجم",
        "كركر",
        "الشابة",
        "ملولش",
        "سيدي علوان",
        "قصور الساف",
        "البرادعة",
        "سيدي زيد - أولاد مولاهم",
        "الحكايمة",
        "التلالسة",
        "زالبة"
    ],
    [
        "صفاقس",
        "ساقية الزيت",
        "الشيحية",
        "ساقية الدائر",
        "قرمدة",
        "العين",
        "طينة",
        "عقارب",
        "جبنيانة",
        "الحنشة",
        "منزل شاكر",
        "الغريبة",
        "بئر علي بن خليفة",
        "الصخيرة",
        "المحرس",
        "قرقنة",
        "العامرة",
        "العوابد - الخزانات",
        "بئر علي الشمالية",
        "الحاجب",
        "الحزق - اللوزة",
        "النور - صفاقس",
        "النصر"
    ],
    [
        "القيروان",
        "الشبيكة",
        "السبيخة",
        "الوسلاتية",
        "عين جلولة",
        "حفوز",
        "العلا",
        "حاجب العيون",
        "نصر الله",
        "منزل مهيري",
        "الشراردة",
        "بوحجلة",
        "سيسب - الذريعات",
        "جهينة",
        "رقادة",
        "عبيدة",
        "الشوايحية",
        "العين البيضاء",
        "الشواشي"
    ],
    [
        "القصرين",
        "سبيطلة",
        "سبيبة",
        "جدليان",
        "تالة",
        "حيدرة",
        "فوسانة",
        "فريانة",
        "تلابت",
        "ماجل بالعباس",
        "العيون",
        "حاسي الفريد",
        "الزهور - القصرين",
        "النور - القصرين",
        "الشرائع - مشرق الشمس",
        "الرخمات",
        "عين الخمايسية",
        "خمودة",
        "بوزقام"
    ],
    [
        "سيدي بوزيد",
        "جلمة",
        "سبالة",
        "بئر الحفي",
        "سيدي علي بن عون",
        "منزل بوزيان",
        "المكناسي",
        "المزونة",
        "الرقاب",
        "أولاد حفوز",
        "السعيدة",
        "سوق الجديد",
        "الأسودة",
        "الفائض بنور",
        "باطن الغزال",
        "رحال",
        "المنصورة"
    ],
    [
        "قابس",
        "شنني النحال",
        "غنوش",
        "المطوية",
        "وذرف",
        "الحامة",
        "مطماطة القديمة",
        "مطماطة الجديدة",
        "مارث",
        "الزارات",
        "منزل الحبيب",
        "بوشمة",
        "تبلبو",
        "الحامة الغربية",
        "كتانة",
        "دخيلة توجان"
    ],
    [
        "مدنين",
        "بني خداش",
        "بن قردان",
        "جرجيس",
        "جربة حومة السوق",
        "جربة ميدون",
        "جربة أجيم",
        "سيدي مخلوف",
        "جرجيس الشمالية",
        "بوغرارة"
    ],
    [
        "تطاوين",
        "بئر الأحمر",
        "غمراسن",
        "ذهيبة",
        "رمادة",
        "الصمار",
        "تطاوين الجنوبية"
    ],
    [
        "قفصة",
        "القصر",
        "أم العرائس",
        "الرديف",
        "المتلوي",
        "المظيلة",
        "القطار",
        "السند",
        "بلخير",
        "سيدي عيش",
        "لالة",
        "سيدي بوبكر",
        "زانوش"
    ],
    [
        "توزر",
        "دقاش",
        "حامة الجريد",
        "نفطة",
        "تمغزة",
        "حزوة"
    ],
    [
        "قبلي",
        "جمنة",
        "دوز",
        "القلعة",
        "سوق الأحد",
        "الفوار",
        "رجيم معتوق",
        "بشلي - جرسين - البليدات",
        "بشري - فطناسة"
    ]]
export default Arabe_locals