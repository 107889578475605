import React, { useState } from 'react';
import '../assets/styles/Inscription.css';
import ScrollList from '../components/ScrollList';
import { TailSpin } from "react-loader-spinner";
import axios from 'axios'
import CryptoJS from "crypto-js"

export default function CheckActivite() {
    const [selectedValue, setSelectedValue] = useState();
    const [date, setdate] = useState();
    const [show, setShow] = useState(false);
    const [loader, setloader] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const handleConfirm = () => {

        setShowModal(false);
    };

    const handleCancel = () => {
        window.location.replace('/');

        setShowModal(false);
    };
    function handleVerification() {
        setloader(true)
        const numConnexion = localStorage.getItem('U2FsdGVkX19xqKHwGY8nQLnQc8gwVyHkcrKpJBegXRg');
        let decryptedBytes = CryptoJS.AES.decrypt(numConnexion, "MOT_DE_KEY");
        let decryptedDataString = decryptedBytes.toString(CryptoJS.enc.Utf8);
        let DecnumConnexion = JSON.parse(decryptedDataString);
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/getPharmacien?NumeroInscription=${DecnumConnexion}`)
            .then((res) => {
                if (
                    res.data.Activite[0].Activite.LibelleActivite === selectedValue
                ) {
                    window.location.replace('/Profile/Information%20personnelle');
                } else {
                    setShowModal(true)
                }
                setloader(false)
            })
            .catch((err) => setloader(false));
    }

    return (
        <div className='container'>
            <div className='section-one-Inscription'>
                <div className='inner-container-s1'>
                    <img src={require('../assets/7398228.png')} style={{ width: '100px', height: '100px' }} />
                    <span style={{ color: '#FFFF', fontSize: '2.5rem', fontWeight: 600 }}>Veuillez nous confirmer votre activité</span>
                </div>
            </div>
            <div className='section-two'>
                <div className='inscription-section'>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'column', width: '80%', height: '100%' }}>
                        <input onClick={() => setShow(!show)} value={selectedValue} className='inscription-inputs' placeholder='Libellé activité' />

                        <div style={{ flexDirection: 'column', display: 'flex', width: '90%', justifyContent: 'center', alignItems: 'center' }}>
                            {loader ?
                                <button className='inscription-btn-Inscription' style={{ alignItems: "center", display: "flex" }} type='submit' value='SE CONNECTER'>
                                    <TailSpin color="white" height='25px' strokeWidth='3px' radius={"1px"} />
                                </button> :
                                <button className='inscription-btn-Inscription' style={{ width: "50%", fontSize: "70%" }} type='submit' onClick={handleVerification} value='SE CONNECTER'>
                                    Vérification de vos données
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ zIndex: 10 }} className={`confirm-modal ${showModal ? 'show' : ''}`}>
                <div className="modal-content">
                    <p>Echec de la vérification</p>
                    <p>Merci de prendre contact avec la CNOPT pour régulariser votre activité</p>
                    <div className="button-container" >
                        <button onClick={handleConfirm}>Re-saisir mes informations</button>
                        <button onClick={handleCancel}>Revenir à la page d’accueil</button>
                    </div>
                </div>
                <div className="overlay" onClick={() => setShowModal(false)}></div>
            </div>
            {show && <ScrollList onData={(e) => { setSelectedValue(e); setShow(false) }} />
            }        </div>
    );
}
