import styled from 'styled-components';
import axios from "axios"
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TailSpin } from "react-loader-spinner";
import CryptoJS from "crypto-js"
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import the autoTable plugin

const generatePdf = (e, approvalCode, err) => {
    const pdf = new jsPDF();

    // Add title
    pdf.setFontSize(16);
    pdf.text('Récépissé temporaire de paiement', pdf.internal.pageSize.width / 2, 15, 'center');

    // Add table
    const tableData = [
        ['ERROR', err],
        ['ORDER_ID', e],
        ['APPROVAL_CODE', approvalCode],
    ];

    pdf.autoTable({
        startY: 30, // Start the table below the title
        head: [['Key', 'Value']],
        body: tableData,
    });

    return pdf.output('dataurlstring');
};
const Container = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: auto; /* Added margin auto to center horizontally */
  display: flex;
  flex-direction: column; /* Adjusted to a column layout */
  align-items: center;
  justify-content: center;
  margin-top:10rem;
  margin-bottom:20rem
`;

const SuccessIcon = styled.div`
  color: #4CAF50;
  font-size: 80px;
  margin-bottom: 20px;
`;

const Heading = styled.h1`
  color: #333;
`;

const Message = styled.p`
  color: #666;
`;
const MessageTWO = styled.p`
  color: #666;
  font-size:13px
`;


const BackButton = styled.button`
  padding: 10px 20px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px; /* Added margin-top for spacing */

  &:hover {
    background-color: #45a049;
  }
`;

const MobilePaiment = () => {
    const location = useLocation();
    let [data, setData] = useState()
    let [NumeroMouvement, setNumeroMouvement] = useState()
    let [Error, setError] = useState()
    let [Num, setNum] = useState()

    let checkPayment = () => {
        axios
            .get(`https://test.clictopay.com/payment/rest/getOrderStatus.do`, {
                params: {
                    language: 'en',
                    orderId: location?.search?.split('orderId=')[1].split('&')[0],
                    password: "8R8tB6zw",
                    userName: "0799902366",
                },
            }).then((res) => {

                setData(res.data)
            }
            )
            .catch((err) => console.log(err, "err"))
    }

    useEffect(() => {
        checkPayment()
    }, [])

    let sendData = () => {
        setNum(location?.search?.split('Num=')[1].split('&')[0])
        let paymentAmount = data?.Amount;
        if (paymentAmount !== undefined) {
            let requestData = {
                "NumeroInscription": Num,
                "Type": 'Cotisation',
                "ModePaiement": "ClickToPay",
                "Montant": paymentAmount / 1000,
                "OrderId": location?.search?.split('orderId=')[1].split('&')[0],
            };
            axios.post(`https://naughty-zhukovsky.41-231-122-98.plesk.page/api/addTransaction`, requestData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then((res) => console.log(res.data.NumeroMouvement))
                .catch((error) => {
                    console.log(error, 'errrr')
                    window.ReactNativeWebView.postMessage(1836)
                    let requestDataString = JSON.stringify(requestData);
                    let encryptedData = CryptoJS.AES.encrypt(requestDataString, "IN_CASE_ERROR").toString();
                    localStorage.setItem("IN_CASE_ERROR", encryptedData);
                    let e = error;
                    if (error.response) {
                        e = error.response.data;
                        if (error.response.data && error.response.data.error) {
                            e = error.response.data.error;
                        } else {
                            e = error.response.data.message;
                        }
                    } else if (error.message) {
                        e = error.message;
                    } else {
                        e = "Unknown error";
                    }
                    setError(e)
                    console.log(e);
                });
        }
    };

    useEffect(() => {
        if (data?.ErrorMessage === "Success") {
            sendData();
        }
    }, [data]);
    const onButtonClick = (e, approvalCode, err) => {
        const pdfDataUrl = generatePdf(e, approvalCode, err);
        const link = document.createElement('a');
        link.href = pdfDataUrl;
        link.download = 'Récépissé_temporaire_de_paiement.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <Container>
            {!Error ?
                <>
                    {data?.ErrorMessage ?
                        <>
                            <Heading>{data?.ErrorMessage === "Success" ? "Paiement réussi !" : "Désole"}</Heading>
                            <Message>{data?.ErrorMessage === "Success" ? <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div>

                                    Paiement accepté avec succès
                                </div>
                                <div style={{ fontWeight: 600 }}>

                                    APPROVAL_CODE: {data?.approvalCode}
                                </div>
                            </div>
                                :
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <div>

                                        Paiement refusé
                                    </div>
                                    <div style={{ fontWeight: 600, fontSize: 12 }}>
                                        ORDER_ID: {location.search.split('orderId=')[1].split('&')[0]}
                                    </div>
                                </div>
                            }

                            </Message>
                        </>
                        :
                        <TailSpin color="grey" height='100%' width="100%" strokeWidth='1px' radius={"0.1px"} />
                    }
                </>
                :
                <div>
                    {`Nous nous excusons pour toute perturbation. Une situation inattendue s'est produite, mais nous travaillons à la résoudre. Si vous avez des inquiétudes, n'hésitez pas à nous contacter. Votre satisfaction est notre priorité, et nous sommes déterminés à résoudre ce problème.`}
                    <br />
                    <div style={{ fontWeight: 600, width: "100%", fontSize: "90%" }}>

                        ORDER_ID : {location.search.split('orderId=')[1].split('&')[0]}
                    </div>
                    <div style={{ fontWeight: 600, width: "100%", fontSize: "90%" }}>

                        APPROVAL_CODE : {data?.approvalCode}
                    </div>
                    <button onClick={() => onButtonClick(location.search.split('orderId=')[1].split('&')[0], data?.approvalCode, Error)}>
                        Imprimé le code
                    </button>
                </div>}

        </Container>
    );
};

export default MobilePaiment;
