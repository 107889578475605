import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from "crypto-js"

export const pharmacienLogin = createAsyncThunk('login', async (data, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/pharmacienLogin`, data);
    localStorage.setItem(`${process.env.REACT_APP_LOCAL_STORAGE}`, CryptoJS.AES.encrypt(data.NumeroInscription, "MOT_DE_KEY").toString());
    return response.data.message;

  } catch (err) {
    return rejectWithValue(err.message);
  }
});


export const connexion = createSlice({
  name: "login",
  initialState: {
    connexion: null,
    connexions: {
      items: [],
      count: 0,
    },
    error: null, // Initialize error as null
    deleteError: null,
    saveError: null,
    createArticleError: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(pharmacienLogin.fulfilled, (state, action) => {
      state.connexions.items = action.payload;
    });
  },
});

export default connexion.reducer;
