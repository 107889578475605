import React, { useState } from 'react'
import masterCard from "../assets/images/mastercard.png"
import visa from "../assets/images/visa.png"
import banc from "../assets/logo-virement-bancaire.png"
import '../assets/styles/Ctisation_Two.css'
import axios from 'axios'
import CryptoJS from "crypto-js"

import { ToastContainer, toast } from 'react-toastify';
export default function AbsoluteBox({ input }) {

    const [showModal, setShowModal] = useState(false);

    const handleConfirm = async (e) => {
        try {
            const reader = new FileReader();
            const file = e.target.files[0];
            reader.readAsDataURL(file);

            reader.onload = async () => {
                const binaryData = new Uint8Array(reader.result);
                const numConnexion = localStorage.getItem('U2FsdGVkX19xqKHwGY8nQLnQc8gwVyHkcrKpJBegXRg');
                let decryptedBytes = CryptoJS.AES.decrypt(numConnexion, "MOT_DE_KEY");
                let decryptedDataString = decryptedBytes.toString(CryptoJS.enc.Utf8);
                let DecnumConnexion = JSON.parse(decryptedDataString);
                let formData = new FormData();
                formData.append("NumeroInscription", DecnumConnexion);
                formData.append("Type", 'Cotisation');
                formData.append("ModePaiement", "Par virement");
                formData.append("Montant", input[0]);
                formData.append("PreuveVirement", file);
                try {
                    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/addTransaction`, formData);
                    let requestDataString = JSON.stringify(response.data);
                    let encryptedData = CryptoJS.AES.encrypt(requestDataString, "DATA").toString();
                    window.location.href = `https://pharmacien.cnopt.tn/status_Bancaire?data=${encryptedData}`;
                } catch (error) {
                    console.log(error, 'errrr');
                }
            };
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    let [checked, setCheked] = useState("first" || "second")
    let pay = () => {
        if (input?.length > 0) {
            if (checked == 'first') {
                let multip = input[2] == 0 ? 1:input[2] 
                let max = input[1] * multip
                if (input[0] / 1000 <= max) {

                    if (input[0] / 1000 % input[1]?.split('.')[0] !== 0 || input[0] / 1000 === 0) {
                        let x = (input[0] / 1000) / input[1]?.split('.')[0]
                        let num = x < 1 ? 1 : Math.floor(x + 0.5)
                        toast.warn(`le montant doit être d'au moins ${input[1]?.split('.')[0] * num} DT`)

                    } else {

                        checked && input[0] &&
                            axios
                                .get(`${process.env.REACT_APP_CLICKTOPAY}/payment/rest/register.do?userName=${process.env.REACT_APP_USERNAME}&amount=${input[0]}&language=en&orderNumber=${Math.floor(Math.random() * 10000000000)}&password=${process.env.REACT_APP_PASSWORD}&returnUrl=https://pharmacien.cnopt.tn/status_check&failUrl=https://pharmacien.cnopt.tn/failed_check`).then((res) => window.location.href = res.data.formUrl).catch((err) => console.log(err))
                    }
                }
                else {
                    toast.warn(`Le montant doit être de ${max} DT maximum`)

                }

            } else {
                setShowModal(true)
            }
        }
    }

    return (
        <div className='absulote_box'>
            <ToastContainer />

            <div className='absolut_box_two'>
                <div className='first_absolute'>Mode de paiement</div>
                <div className='midlle_absolute'>
                    <div className='checkbox-container'>
                        <input className='cotisation-checkbox' checked={checked === 'first'} onChange={() => setCheked('first')} type='checkbox' />
                        <div className='paiment-text-holder'>
                            <img src={masterCard} style={{ height: "2rem", width: "2.5rem", marginRight: 20 }} />
                            <img src={visa} style={{ height: "1.5rem", width: "3rem" }} />
                        </div>
                    </div>
                    {/* <div className='checkbox-container'>
                        <input className='cotisation-checkbox' checked={checked === 'second'} onChange={() => setCheked('second')} type='checkbox' />
                        <div className='paiment-text-holder'>
                            <img src={banc} style={{ height: "3.2rem", width: "7.2rem", marginRight: 20 }} />
                        </div>
                    </div> */}
                </div>
                <div className='sec_absolute' onClick={pay}>Passer au paiement</div>
            </div>
            {/* <div className='absolut_box_third'>
                <div className='buttons'><BsPrinter /> Imrimer le recu</div>
                <div className='buttons'><BsPrinter /> Imrimer le relevé de la cotisation</div>
            </div> */}
            <div style={{ zIndex: 10 }} className={`confirm-modal ${showModal ? 'show' : ''}`}>
                <div className="modal-content" style={{ backgroundImage: `url('../assets/images/visa.png')` }}>
                    <p>Veuillez effectuer le virement bancaire vers l’un des comptes suivants.</p>
                    <span>
                        Compte Amen Bank :<br />
                        RIB : 07 309 0075 105 561193 19<br />
                        Domiciliation : Alain Savary<br />
                        Titulaire du Compte : CNOPT<br />
                        <br />
                        Compte UIB :<br />
                        12 030 0000093002576 72<br />
                        Domiciliation : AG ALAIN SAVARY<br />
                        Titulaire du Compte : CNOPT
                    </span>


                    <p>Veuillez sélectionner une preuve de transfert</p>
                    <div className="button-container">
                        <input placeholder='ee' onChange={(e) => handleConfirm(e)} type='file' />
                    </div>
                </div>
                <div className="overlay" onClick={() => setShowModal(false)}></div>
            </div>
        </div>
    )
}
