import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';



export const getActiviteList = createAsyncThunk('activiteList', async (payload, thunkAPI) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getActiviteList`);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});



export const AcitiviteList = createSlice({
    name: "acitiviteList",
    initialState: {
        acitivite: null,
        activiteList: {
            items: [],
            count: 0,
        },
        error: null, // Initialize error as null
        deleteError: null,
        saveError: null,
        createArticleError: null,
    },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getActiviteList.fulfilled, (state, action) => {
            state.activiteList.items = action.payload;
        });
    }

});

export default AcitiviteList.reducer;
