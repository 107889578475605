import React, { useState } from 'react';
import '../assets/styles/Connexion.css';
import { useDispatch } from 'react-redux';
import { pharmacienLogin } from "../redux/Connexion";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TailSpin } from "react-loader-spinner";



export default function Connexion() {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const successToast = (message) => {
    toast.success("authentification réussite");
    setLoading(false)

    setTimeout(() => {
      window.location.replace('/Profile/Information%20personnelle');
    }, 2000)
  };

  const errorToast = (message) => {
    toast.error(message);
    setLoading(false)
  };

  const handlePharmacienLogin = () => {
    setLoading(true)

    dispatch(pharmacienLogin(data))
    
      .then((resultAction) => {
        if (pharmacienLogin.fulfilled.match(resultAction)) {
          successToast();
        } else if (pharmacienLogin.rejected.match(resultAction)) {
          errorToast(resultAction.payload);
        }
      });

  };

  return (
    <div className='Connexion-container'>
      <ToastContainer />
      <div className='section-one'>
        <div className='inner-container-s1'>
          <span style={{ color: "#FFFF", fontSize: "2.5rem", fontWeight: 600 }}>Connexion</span>
          <span style={{ color: "#FFFF", fontSize: "1.2rem", width: "70%" }}>Saisissez vos identifiants de connexion pour accéder à votre compte</span>
        </div>
      </div>
      <div className='section-two'>
        <div className='Connexion-section'>
          <input className='Connexion-inputs' onChange={(e) => setData({ ...data, NumeroInscription: e.target.value })} placeholder="Numéro d’inscription " />
          <input className='Connexion-inputs' type='password' onChange={(e) => setData({ ...data, MotDePasse: e.target.value })} placeholder='Mot de passe' />
          {loading ? <div className='Loader-btn'>
            <TailSpin color="white" height='25px' strokeWidth='3px' radius={"1px"} />
          </div> : <input className='Connexion-btn' type='submit' onClick={handlePharmacienLogin} value='SE CONNECTER' />
          }
        </div>
      </div>
    </div>
  );
}
