import React, { useEffect } from 'react'
import '../assets/styles/Inscription.css'
import { NavLink } from "react-router-dom";
import Carousel from "../layout/Carousel-inscription"

export default function Inscription() {

  useEffect(() => {
    let check = localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE);
    if (check) {
      window.location.replace('/Profile/Information%20personnelle');
    }
  }, [])

  return (
    <div className='container'>
      <div className='section-one-Inscription'>
        <div className='inner-container-s1'>
          <span style={{ color: "#FFFF", fontSize: "2.5rem", fontWeight: 600 }}>Inscription</span>
          <span style={{ color: "#FFFF", fontSize: "1.2rem", width: "70%" }}>Saisissez vos informations pour vous inscrire à votre espace
          </span>
        </div>
      </div>
      <div className='section-two'>
        <div className='inscription-section'>
          <Carousel />
          <span style={{ fontSize: "0.8rem", marginBottom: "1rem" }} >Vous avez déjà un compte? <NavLink to="/Connexion" style={{ color: "#03BCCD" }}>S'identifier</NavLink>  </span>
        </div>
      </div>
    </div>
  )
}
