import { useState, useEffect } from 'react';
import 'swiper/css';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../assets/styles/ProfileCarousel.css'
import { updatePharmacien } from '../redux/getPharmacien'
import formatDateReader from "../helpers/ProifleDateReader"
import 'react-toastify/dist/ReactToastify.css';
import CountriesScroll from '../components/CountriesScroll';
import VilleScroll from '../components/VilleScroll';
import { pharmacienInscription } from "../redux/InscriptionStore"
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export default () => {
    const dispatch = useDispatch();
    const PharmacienData = useSelector((state) => (state.getPharmacien?.getPharmaciendata?.items))
    const [motDePasse, setmotDePasse] = useState([]);
    const [selectedCategoryArabe, setSelectedCategoryArabe] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [Data, setData] = useState({});
    const [disabled, setdisabled] = useState(true);
    const [show, setShow] = useState()
    const [showVille, setShowVille] = useState()
    const [selectedValue, setSelectedValue] = useState()
    const [selectedVille, setSelectedVille] = useState()
    const [imageSrc, setImageSrc] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    let changeMotDePasse = () => {
        let data = { ...motDePasse, NumeroInscription: PharmacienData?.NumeroInscription, CIN: PharmacienData?.CIN }
        if (motDePasse.reMotDePasse && motDePasse.MotDePasse && motDePasse.reMotDePasse === motDePasse.MotDePasse) {
            dispatch(pharmacienInscription(data))
                .then((resultAction) => {
                    if (pharmacienInscription.fulfilled.match(resultAction)) {
                        successToast('Le mot de passe a été changé avec succès');
                    } else if (pharmacienInscription.rejected.match(resultAction)) {
                        errorToast(resultAction.payload);
                    }
                })
        } else {
            errorToast("Confirm votre mote de passe");
        }
    }

    useEffect(() => {
        setData(PharmacienData)
    }, [PharmacienData])

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };


    const handleCategoryChangeArabe = (e) => {
        setSelectedCategoryArabe(e.target.value);
    };

    const successToast = (message) => {
        toast.success(message);
        setdisabled(false)
    };

    const errorToast = (message) => {
        toast.error(message);
    };


    let getPhoto = () => {
        const data = PharmacienData?.Photo?.data?.data;
        const imageData = PharmacienData && PharmacienData.Photo && PharmacienData.Photo.data && PharmacienData.Photo.data.data;
        if (imageData?.length > 0) {
            const uint8Array = new Uint8Array(imageData);
            const binaryString = uint8Array.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
            const base64String = btoa(binaryString);
            const bufferDataUrl = `data:image/png;base64,${base64String}`;
            setImageSrc(bufferDataUrl);
        }
    }

    let uploadPhoto = () => {
        let formData = new FormData();
        formData.append("NumeroInscription", PharmacienData?.NumeroInscription);
        formData.append("Photo", selectedFile);

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/updatePharmacienPhoto`, formData)
            .then((res) => toast.success(res.data.message))
            .catch((err) => console.log(err.response.data));
    }

    useEffect(() => {
        if (!imageSrc) {
            getPhoto()
        }
    }, [Data]); // Include imageSrc in the dependency array



    const handleUpdatePharmacien = async () => {
        const {
            AdressePersonnelle,
            AdressePersonnelleArabe,
            VilleAdressePersonnelle,
            VilleAdressePersonnelleArabe,
            GouvernoratPersonnel,
            GouvernoratPersonnelArabe,
            CodePostalPersonnel,
            DateNaissance,
            NumeroInscription, EmailPersonnel, TelFixePersonnel, TelMobilePersonnel
        } = Data;
        const extractedData = {
            AdressePersonnelle,
            AdressePersonnelleArabe,
            VilleAdressePersonnelle,
            VilleAdressePersonnelleArabe,
            GouvernoratPersonnel,
            GouvernoratPersonnelArabe,
            CodePostalPersonnel,
            DateNaissance,
            NumeroInscription, EmailPersonnel, TelFixePersonnel, TelMobilePersonnel
        };
        dispatch(updatePharmacien(extractedData))
            .then((res) => {
                if (updatePharmacien.fulfilled.match(res)) {
                    successToast(res.payload.message);
                } else if (updatePharmacien.rejected.match(res)) {
                    errorToast("Something went wrong");
                }
                setdisabled(!disabled)
            })
            .catch((err) => {

                errorToast("Something went wrong")
                setdisabled(!disabled)
            }
            )
    };

    return (
        <div style={{ width: "90%", height: "auto", display: "flex", flexDirection: "column", overflow: "auto" }}>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", flex: 0.8 }}>
                <div className='Profile-section'>
                    <ToastContainer />

                    <div className='profile-pic'>
                        {imageSrc ? (
                            <img
                                src={selectedFile ? URL.createObjectURL(selectedFile) : imageSrc}
                                style={{
                                    height: '13rem',
                                    width: '13rem',
                                    borderRadius: '1rem',
                                    border: '10px solid white',
                                    objectFit: 'contain',
                                }}
                                className='shadowed-image'
                                alt='Profile'
                            />
                        )
                            : (
                                <img
                                    src={"https://cdn-icons-png.flaticon.com/512/8742/8742495.png"}
                                    style={{
                                        height: '18rem',
                                        width: '18rem',
                                        borderRadius: '1rem',
                                        border: '10px solid white',
                                        objectFit: 'contain',
                                    }}
                                    className='shadowed-image'
                                    alt='Profile'
                                />
                            )
                        }
                        {!selectedFile ?
                            <>
                                <input
                                    type='file'
                                    accept='image/*'
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    id='fileInput'
                                />
                                <label
                                    htmlFor='fileInput'
                                    style={{ color: 'black', fontWeight: 600, cursor: 'pointer' }}
                                >
                                    Changer la photo
                                </label>
                            </>
                            :
                            <span
                                onClick={uploadPhoto}
                                style={{ color: 'black', fontWeight: 600, cursor: 'pointer' }}
                            >
                                Sauvegarder les modifications
                            </span>
                        }
                    </div>
                    <div className='profile-info'>
                        <div className='input-holder'> <spn style={{ fontSize: 20, color: "#00B16A", fontWeight: 500 }} >{PharmacienData?.Nom + " " + PharmacienData?.Prenom}</spn> <span style={{ fontSize: 18, color: "grey", fontWeight: 500 }}>{PharmacienData?.LieuNaissance + " - " + PharmacienData?.DateNaissance?.split('T')[0]}</span>   </div>
                        <span style={{ fontSize: 18, color: "grey", fontWeight: 500, textAlign: "left", width: "90%" }}>Numero d'inscription Cnopt :{PharmacienData?.NumeroInscription}</span>
                        <div className='input-holder'><span className='profile-text'>Changer Mot de passe</span>  <input type={showPassword ? 'text' : 'password'} onChange={(e) => setmotDePasse({ ...motDePasse, MotDePasse: e.target.value })} placeholder='Mot de passe' className='profile-input' /></div>
                        <div className='input-holder-eye'><span className='profile-text'>Confirmer Mot de passe</span><input type={showPassword ? 'text' : 'password'} onChange={(e) => setmotDePasse({ ...motDePasse, reMotDePasse: e.target.value })} placeholder='Mot de passe' className='profile-input' /> {showPassword ? (
                            <FaEyeSlash className='password-toggle-icon' onClick={toggleShowPassword} />
                        ) : (
                            <FaEye className='password-toggle-icon' onClick={toggleShowPassword} />
                        )}</div>
                        <div className='button-holder' style={{ cursor: "pointer" }} onClick={changeMotDePasse} >Enregistrer</div>


                    </div>
                </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-around", flexDirection: "column" }}>
                <span className='profile-text-title-c'>Information personnelle</span>
                <div className='first-inpt-holder' >
                    <span className='profile-text'>Date de naissance</span>
                    <input type='date' disabled value={formatDateReader(PharmacienData?.DateNaissance || '')} className='coordonnees_personnelle_input' /> </div>

                <div className='coor-inputs-holders'>
                    <div className='innerCoorInputsHolder'>
                        <span className='profile-text'>Lieu de naissance</span>
                        <input disabled value={PharmacienData?.LieuNaissance} className='coordonnees_personnelle_input' />
                    </div>
                    <div className='innerCoorInputsHolder'>
                        <span className='profile-text' style={{ textAlign: "end" }}>مكان الولادة</span>
                        <input
                            disabled
                            style={{ textAlign: 'end' }}
                            placeholder="تونس"
                            value={selectedCategoryArabe || PharmacienData?.LieuNaissanceArabe || ''}
                            onChange={handleCategoryChangeArabe}
                            className='coordonnees_personnelle_input' />
                    </div>
                </div>
                <div className='coor-inputs-holders'>
                    <div className='innerCoorInputsHolder'>
                        <span className='profile-text'>Nationalité</span>
                        <input disabled placeholder='Aa' value={PharmacienData?.Nationalite || ''} className='coordonnees_personnelle_input'

                        />
                    </div>
                    <div className='innerCoorInputsHolder'>
                        <span className='profile-text' style={{ textAlign: "end" }}>الجنسية</span>
                        <input placeholder='اج' disabled value={PharmacienData?.NationaliteArabe || ''} style={{ textAlign: 'end' }} className='coordonnees_personnelle_input'

                        />
                    </div>
                </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-evenly", flexDirection: "column" }}>
                <span className='profile-text-title-c'>Coordonnées personnelles</span>
                <div className='first-inpt-holder' >
                    <span className='profile-text'>Email Personnel</span>
                    <input type='email' disabled={disabled} value={Data?.EmailPersonnel} onChange={(e) => {
                        setData({ ...Data, EmailPersonnel: e.target.value });
                    }} className='coordonnees_personnelle_input' /> </div>

                <div className='coor-inputs-holders'>
                    <div className='innerCoorInputsHolder'>
                        <span className='profile-text'>Gouvernorat</span>
                        <input disabled={disabled} onClick={() => setShow(true)} className='coordonnees_personnelle_input' value={selectedValue ? selectedValue[0] : Data?.GouvernoratPersonnel} placeholder='Aa' />
                    </div>
                    <div className='innerCoorInputsHolder'>
                        <span className='profile-text' style={{ textAlign: "end" }}>الولاية</span>
                        <input disabled={disabled} className='coordonnees_personnelle_input' value={selectedValue ? selectedValue[1] : Data?.GouvernoratPersonnelArabe} onChange={(e) => {
                            setData({ ...Data, GouvernoratPersonnelArabe: e.target.value });
                        }} style={{ textAlign: "end" }} placeholder='اج' />
                    </div>
                </div>
                <div className='coor-inputs-holders'>
                    <div className='innerCoorInputsHolder'>
                        <span className='profile-text'>Ville</span>
                        <input disabled={disabled} onClick={() => setShowVille(true)} className='coordonnees_personnelle_input' value={selectedVille ? selectedVille[0] : Data?.VilleAdressePersonnelle} onChange={(e) => {
                            setData({ ...Data, VilleAdressePersonnelle: e.target.value });
                        }} placeholder='Aa' />
                    </div>
                    <div className='innerCoorInputsHolder'>
                        <span className='profile-text' style={{ textAlign: "end" }}>المدينة</span>
                        <input disabled={disabled} className='coordonnees_personnelle_input' value={selectedVille ? selectedVille[1] : Data?.VilleAdressePersonnelleArabe} onChange={(e) => {
                            setData({ ...Data, VilleAdressePersonnelleArabe: e.target.value });
                        }} style={{ textAlign: "end" }} placeholder='اج' />
                    </div>
                </div>

                <div className='coor-inputs-holders'>
                    <div className='innerCoorInputsHolder'>
                        <span className='profile-text'>Adresse</span>
                        <input disabled={disabled} className='coordonnees_personnelle_input' value={Data?.AdressePersonnelle || ''} onChange={(e) => {
                            setData({ ...Data, AdressePersonnelle: e.target.value });
                        }} placeholder='Aa' />
                    </div>
                    <div className='innerCoorInputsHolder'>
                        <span className='profile-text' style={{ textAlign: "end" }}>العنوان</span>
                        <input disabled={disabled} className='coordonnees_personnelle_input' value={Data?.AdressePersonnelleArabe || ''} onChange={(e) => {
                            setData({ ...Data, AdressePersonnelleArabe: e.target.value });
                        }} style={{ textAlign: "end" }} placeholder='اج' />
                    </div>
                </div>
                <div className='coor-inputs-holders'>
                    <div className='innerCoorInputsHolder'>
                        <span className='profile-text'>Telephone Fix</span>
                        <input disabled={disabled} className='coordonnees_personnelle_input' value={Data?.TelFixePersonnel || ''} onChange={(e) => {
                            setData({ ...Data, TelFixePersonnel: e.target.value });
                        }} placeholder='Aa' />
                    </div>
                    <div className='innerCoorInputsHolder'>
                        <span className='profile-text' style={{ textAlign: "end" }}>Telephone Mobile</span>
                        <input disabled={disabled} className='coordonnees_personnelle_input' value={Data?.TelMobilePersonnel || ''} onChange={(e) => {
                            setData({ ...Data, TelMobilePersonnel: e.target.value });
                        }} style={{ textAlign: "end" }} placeholder='اج' />
                    </div>
                </div>

                {
                    disabled ?
                        <div className='btn-holder-c'>

                            <button className='button-coor' onClick={() => { setdisabled(!disabled) }}>Mettre à jour</button>
                        </div>
                        :

                        <div className='btn-holder-c'>

                            <button className='button-coor' onClick={handleUpdatePharmacien}>Save changes</button>
                        </div>

                }
            </div>
            {show && <CountriesScroll onCountry={(e, i, index) => { setSelectedValue([e, i, index]); setShow(false); setData({ ...Data, GouvernoratPersonnel: e, GouvernoratPersonnelArabe: i }) }} />
            }
            {selectedValue && showVille && <VilleScroll index={selectedValue[2]} onVille={(e, i) => { setSelectedVille([e, i]); setShowVille(false); setData({ ...Data, VilleAdressePersonnelle: e, VilleAdressePersonnelleArabe: i }) }} />
            }
        </div>
    );
};
