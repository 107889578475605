import React, { useEffect, useState } from 'react'
import '../assets/styles/Cotisation.css'

import 'primereact/resources/themes/bootstrap4-light-blue/theme.css'
import axios from "axios"
import NavigationBtns from '../components/navigationBtns';
import { getPharmacien } from "../redux/getPharmacien"
import { getPharmacienCotisation } from "../redux/getPharmacienCotisation"
import { useSelector, useDispatch } from "react-redux";
import formatDate from '../helpers/DateReader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import "../assets/styles/Ctisation_Two.css"
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import AbsoluteBox from '../components/AbsoluteBox'
import { FaDownload } from "react-icons/fa6";

export default function Cotisation() {
  let [input, setInput] = useState()
  const dispatch = useDispatch();
  const Transaction = useSelector((state) => (state.getPharmacien?.getPharmaciendata?.items?.Transaction))
  const PharmacienCotisation = useSelector((state) => (state.getPharmacienCotisation.getPharmacienCotisationdata.items))
  const navigate = useNavigate();

  useEffect(() => {
    const numConnexion = localStorage.getItem('U2FsdGVkX19xqKHwGY8nQLnQc8gwVyHkcrKpJBegXRg');
    if (!numConnexion) {
      navigate('/');
    }
  }, [navigate]);
  useEffect(() => {
    dispatch(getPharmacien());
  }, []);

  useEffect(() => {
    dispatch(getPharmacienCotisation());
  }, []);

  const downloadPdf = (NumeroMouvement) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/getPaymentReceipt?ID=${NumeroMouvement}`).then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'REÇU_DE_PAIEMENT.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }).catch((err) => console.log(err))
};

  const renderSkeletonLoader = () => {
    const skeletonRows = [];
    for (let i = 0; i < 5; i++) {
      skeletonRows.push(
        <div key={i} className="skeleton-row">
          <div className="skeleton-date"></div>
          <div className="skeleton-transaction-info">
            <div className="skeleton-transaction-id"></div>
            <div className="skeleton-transaction-id"></div>
            <div className="skeleton-transaction-value"></div>
          </div>
        </div>
      );
    }
    return skeletonRows;
  };

  return (
    <div className='Cotisation-container'>
      <div className='inner-navbar'>
        <NavigationBtns /> </div>
      <ToastContainer />

      <div className='Cotisation-body'>
        <div className='cotisation-title-section'> <span className='cotisation-title'>Cotisation</span><span className='line'></span> </div>
        <div className='section-one-cotisation'>
          <div className='first_div'>
            <div className='First_div_view'>
              <div className='First_View_container' >Total Payé</div>
              <div className='First_div_text' >{PharmacienCotisation?.PayeTotal ? PharmacienCotisation?.PayeTotal : "0.00"} Dt</div>
            </div>
            <div className='First_div_view'>
              <div className='missed_box' >Solde actuel
                de cotisations</div>
              <div className='First_div_text' >{PharmacienCotisation?.Actuel ? PharmacienCotisation?.Actuel : "0.00"} Dt</div>
            </div>
            <div className='First_div_view'>
              <div className='First_View_container' >Total affecté</div>
              <div className='First_div_text' >{PharmacienCotisation?.AffecteTotal ? PharmacienCotisation?.AffecteTotal : "0.00"} Dt</div>
            </div>
          </div>
          {Transaction?.length ?
            <div className="Second_view">
              {Transaction
                ? Transaction.slice().reverse()?.map((e, i) => (
                  // <div key={i} className='inner-histo-section'>
                  //   <div className='cotisation-histo-date'>{e?.Date && formatDate(e?.Date)}</div>
                  //   <div className='inner-histo-section-two'>
                  //     <div className='cotisation-histo-date-checek'>{e?.Date && formatDate(e?.Date)}</div>
                  //     <span className='Numérodetransaction'>Numéro de transaction: </span>
                  //     <span className='code'>#{e?.ID}</span>
                  //     <div className='montant'>
                  //       <span style={{ color: 'white', fontWeight: 600, fontSize: '100%' }}>{e?.Montant}DT</span>
                  //     </div>

                  //    {/* { e?.ModePaiement == "ClickToPay" ? <span style={{width:"10%",display:"flex",alignItems:"center",paddingLeft:10}}>download</span>:<span style={{width:"20%"}}></span>} */}
                  //   </div>
                  // </div>
                  <div style={{ width: "99%", display: "flex", flexWrap: "wrap", margin: 2, padding: 5 }}>
                  <div style={{ margin: 5, color: "gray", flexBasis: "30%" }}>
                      {e?.Date && formatDate(e?.Date)}
                  </div>
                  <div style={{ margin: 5, flexBasis: "30%" }}>
                      <span style={{ color: "#00B16A", fontWeight: 600 }}>Numéro de transaction:</span>
                      <span style={{ marginLeft: 5, color: "#383F73", fontWeight: 500 }}>#{e?.ID}</span>
                  </div>
                  <div style={{ margin: 5, backgroundColor: "#00B16A", borderRadius: 6, padding: 3, color: 'white', fontWeight: 600, fontSize: '100%', alignItems:"center",flexBasis: "30%" }}>
                      {e?.Montant}DT
                  </div>
                  {e?.ModePaiement === "ClickToPay" && 
                      <div style={{ margin: 5, backgroundColor: "#00B16A", borderRadius: 6, padding: 5, color: 'white', fontWeight: 600, fontSize: '100%', cursor: 'pointer' }} onClick={() => downloadPdf(e.ID)}>
                          <FaDownload color={"white"} />
                      </div>
                  }
              </div>
              
              
                ))
                : renderSkeletonLoader()
              }
            </div> : null}
          <div className='Third'>
            <div style={{ flex: 1 }}>
              <div className='paiment-title'>
                <div className='title-container'><span className='section-two-title'>Paiement</span></div>
              </div>
            </div>
            <div className='Third_Vholder'>
              <div className='Third_Second_View'>
                <div className='input-container'>
                  <span className='input-title'>Année de cotisation non payé :</span>
                  <div className='historique-section2'>

                    {
                      PharmacienCotisation?.Actuel !== 0 && PharmacienCotisation?.Affecte?.some(e => !e.TransactionId) ? (
                        <div className='inner-histo-section'>
                          {PharmacienCotisation?.Affecte?.map((e, i) => (
                            !e.TransactionId ? (
                              <div key={i} style={{ flexDirection: 'row', display: "flex", justifyContent: 'space-between' }}>
                                <div className='cotisation-histo-date_histo'>{e?.DateAffectationCotisation && formatDate(e?.DateAffectationCotisation)}</div>
                                <span style={{ color: "black", fontWeight: 600, fontSize: '100%' }} >{e?.MontantCotisation}DT</span>
                              </div>
                            ) : null
                          ))}
                        </div>
                      ) : (
                        <span style={{ color: 'grey', width: "95%" }}>Il n'y a pas de cotisations impayées</span>
                      )
                    }

                  </div>
                  {
                    PharmacienCotisation?.Actuel > 0 ?
                      <>
                        <span className='input-title'>Quel montant souhaitez-vous cotiser ?</span>
                        <input min={PharmacienCotisation?.Affecte[PharmacienCotisation?.Affecte?.length - 1]?.MontantCotisation} onChange={(e) => setInput([e.target.value + "000", PharmacienCotisation?.Affecte[PharmacienCotisation?.Affecte?.length - 1]?.MontantCotisation,PharmacienCotisation?.Affecte?.filter(e => !e.TransactionId).length])} className='input-one' placeholder={"0.00DT"} type='number' />
                      </>
                      : (
                        null
                      )
                  }
                </div>
              </div>
              <div style={{ margin: 5, flex: 1 }}>
                <AbsoluteBox input={input} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
