import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CryptoJS from "crypto-js"


export const getPharmacien = createAsyncThunk("pharmacien", async (id) => {
  try {
  const numConnexion = localStorage.getItem('U2FsdGVkX19xqKHwGY8nQLnQc8gwVyHkcrKpJBegXRg');
  let decryptedBytes = CryptoJS.AES.decrypt(numConnexion, "MOT_DE_KEY");
  let decryptedDataString = decryptedBytes.toString(CryptoJS.enc.Utf8);
  let DecnumConnexion = JSON.parse(decryptedDataString);

    const response = numConnexion && await axios.post(`${process.env.REACT_APP_BASE_URL}/api/getPharmacien?NumeroInscription=${DecnumConnexion}`);
    return response.data;
  } catch (err) {
    console.log(err)
  }
}
);

export const updatePharmacien = createAsyncThunk("updatePharmacien", async (body, { dispatch }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/updatePharmacien`, body);
  dispatch(getPharmacien(body.NumeroInscription))
  return response.data;
}
);

export const AddActiviteList = createAsyncThunk('activiteList', async (body, { dispatch }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/addDemandeNewActivite`, body);
  dispatch(getPharmacien(body.NumeroInscription))
  return response.data;
});




export const pharmacien = createSlice({
  name: "pharmacien",
  initialState: {
    getPharmacien: null,
    getPharmaciendata: {
      items: [],
    },
    error: null,
    deleteError: null,
    saveError: null,
    createArticleError: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getPharmacien.fulfilled, (state, action) => {
      state.getPharmaciendata.items = action.payload;
    });
  },
});
export default pharmacien.reducer;