import { Swiper, SwiperSlide } from 'swiper/react';
import { useState, useRef } from 'react';
import 'swiper/css';
import { ToastContainer, toast } from 'react-toastify';
import { pharmacienInscription } from "../redux/InscriptionStore"
import { useDispatch } from 'react-redux';
import { TailSpin } from "react-loader-spinner";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


export default () => {
    const dispatch = useDispatch();
    const swiperRef = useRef(null);
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false);
    const successToast = (message) => {
        toast.success(message);
        setLoading(false)
        setTimeout(() => {
            window.location.replace('/check');
        }, 4000)
    };
    const errorToast = (message) => {
        setLoading(false)
        toast.error(message);
    };

    const handleSuivantClick = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(1);
        }
    };

    const handlePharmacienInscription = async () => {
        setLoading(true)
        try {
            await dispatch(pharmacienInscription(data));
            if (data.reMotDePasse === data.MotDePasse) {
                dispatch(pharmacienInscription(data))
                    .then((resultAction) => {
                        if (pharmacienInscription.fulfilled.match(resultAction)) {
                            successToast(resultAction.payload);
                        } else if (pharmacienInscription.rejected.match(resultAction)) {
                            errorToast(resultAction.payload);
                        }
                    })
            } else {

                errorToast("Confirm votre mote de passe");
            }
        } catch (err) {
            console.log(err)
        }
    };

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Swiper
                ref={swiperRef}
                spaceBetween={50}
                slidesPerView={1}
                style={{ height: "100%" }}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
            >
                <SwiperSlide style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", flexDirection: "column" }}>
                    <input className='inscription-inputs-date' type='date' onChange={(e) => setData({ ...data, DateDeNaissance: e.target.value })} placeholder='Date De Naissance :' />
                    <input className='inscription-inputs' onChange={(e) => setData({ ...data, NumeroInscription: e.target.value })} placeholder="Numéro d'inscription" />
                    <input className='inscription-inputs' onChange={(e) => setData({ ...data, CIN: e.target.value })} placeholder='Numéro CIN' />
                    <div style={{ flexDirection: "column", display: "flex", width: "80%", justifyContent: "center", alignItems: "center" }}>
                        <button className='inscription-btn-Inscription' onClick={handleSuivantClick}>SUIVANT</button>
                    </div>
                </SwiperSlide>
                <ToastContainer />
                <SwiperSlide style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", flexDirection: "column" }}>
                    <input className='inscription-inputs' type='password' onChange={(e) => setData({ ...data, MotDePasse: e.target.value })} placeholder="Mot de passe" />
                    <input className='inscription-inputs' type='password' onChange={(e) => setData({ ...data, reMotDePasse: e.target.value })} placeholder='Retaper mot de passe' />

                    {loading ?
                        <div className='Loader-btn-Inscription'>
                            <TailSpin color="white" height='25px' strokeWidth='3px' radius={"1px"} />
                        </div> :
                        <div style={{ flexDirection: "column", display: "flex", width: "80%", justifyContent: "center", alignItems: "center" }}>
                            <button className='inscription-btn-Inscription' onClick={handlePharmacienInscription} type='submit' value='SE CONNECTER'>SE CONNECTER</button>
                        </div>
                    }
                </SwiperSlide>
            </Swiper>
        </div>
    );
};
