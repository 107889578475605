import React, { useState, useEffect } from 'react';
import '../assets/styles/Documents.css';
import pdfIcon from "../assets/images/pdf-icon.png";
import NavigationBtns from '../components/navigationBtns';
import { BsDownload } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import fetchAndDownloadPDF from '../apis/DownloadPdf'
import { ToastContainer, toast } from 'react-toastify';

export default function Documents() {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [documents, setDocuments] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const numConnexion = localStorage.getItem('U2FsdGVkX19xqKHwGY8nQLnQc8gwVyHkcrKpJBegXRg');
        if (!numConnexion) {
            navigate('/authRequired');
        }
    }, [navigate]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/getDocumentList`)
            .then((res) => {
                setCategories(res.data.categoryList);
                setDocuments(res.data.documentList);
            })
            .catch((err) => console.error(err));
    }, []);

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    const filteredDocuments = selectedCategory
        ? documents.filter(doc => doc.Category === selectedCategory)
        : documents;

    return (
        <div className='Documents-container'>
            <ToastContainer />
            <div className='inner-navbar'>
                <NavigationBtns />
            </div>
            <div className='Documents-body'>
                <div className='Documents-title-section'>
                    <span className='Documents-title'>Annonce</span>
                    <span className='line'></span>
                </div>
                {documents.length ?
                    <div className='Documents-section'>
                        <div className="categories-input-container">
                            <input
                                className="categories-input"
                                list="categoryList"
                                placeholder="Select a category"
                                value={selectedCategory}
                                onChange={handleCategoryChange}
                            />
                            <datalist id="categoryList">
                                {categories.map((category, index) => (
                                    <option value={category} key={index} />
                                ))}
                            </datalist>
                        </div>
                        <div className='Documents-main'>
                            {filteredDocuments.map((doc, i) => (
                                <div className='pdf-container-lol' key={i}>
                                    <div className='icon-container'>
                                        <img loading='lazy' src={pdfIcon} alt='icon' style={{ height: "90%", width: "50%" }} />
                                    </div>
                                    <div className='title-container-lol'>{doc.Name}</div>
                                    <div className='download-container'>
                                        <button onClick={() => { fetchAndDownloadPDF(doc.ID) }} style={{ border: "none", backgroundColor: 'transparent' }}>
                                            <BsDownload />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    :
                    <div style={{ flex: 0.5, display: "flex", justifyContent: "center", alignItems: "center", color: "#00B16A", fontWeight: 500 }}>
                        <span>Il n'y a pas d'annonces</span>
                    </div>
                }
            </div>
        </div>
    );
}
