import React, { useState, useEffect } from 'react'
import formatDateReader from '../helpers/ProifleDateReader'
import { useSelector, useDispatch } from 'react-redux';
import '../../src/assets/styles/Activite.css'
import { getActiviteList } from '../redux/AcitiviteList';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios"
import { getPharmacien } from "../redux/getPharmacien"
import { getPharmacienCotisation } from "../redux/getPharmacienCotisation"

export default function Activite() {
    const dispatch = useDispatch();

    const activiteList = useSelector((state) => state.AcitiviteList.activiteList.items);
    const data = useSelector((state) => (state?.getPharmacien?.getPharmaciendata?.items?.Activite))
    let PharmacienData = data ? data[0] : null

    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCategoryArabe, setSelectedCategoryArabe] = useState('');
    const [disabled, setdisabled] = useState(true);

    const [Data, setData] = useState({});
    useEffect(() => {
        dispatch(getPharmacien());
    }, []);

    useEffect(() => {
        dispatch(getActiviteList())
    }, [])
    useEffect(() => {
        dispatch(getPharmacienCotisation());
    }, []);

    useEffect(() => {
        setData(PharmacienData)
    }, [PharmacienData])

    // const handleCategoryChange = (e) => {
    //     setSelectedCategory(e.target.value);
    // };
    // const handleCategoryChangeArabe = (e) => {
    //     setSelectedCategoryArabe(e.target.value);
    // };

    // const handleActiviteChange = (e) => {
    //     setSelectedActivite(e.target.value);
    // };

    const successToast = (message) => {
        toast.success(message);
        setdisabled(!disabled)
    };

    const errorToast = (message) => {
        toast.error(message);
    };

    const informationList = [
        { label: 'LibelleActivite', value: PharmacienData?.Activite?.LibelleActivite || '' },
        { label: 'DateDebutActivite', value: formatDateReader(PharmacienData?.DateDebutActivite) || '' },
        { label: 'Adresse', value: PharmacienData?.AdresseActivite || '' },
        { label: 'العنوان', value: PharmacienData?.AdresseActiviteArabe || '' },
        { label: 'Ville', value: PharmacienData?.VilleAdresseActivite || '' },
        { label: 'المدينة', value: PharmacienData?.VilleAdresseActiviteArabe || '' },
        { label: 'Gouvernorat', value: PharmacienData?.GouvernoratActivite || '' },
        { label: 'الولاية', value: PharmacienData?.GouvernoratActiviteArabe || '' },
        { label: 'Fax', value: PharmacienData?.FaxActivite ? PharmacienData?.FaxActivite : <span style={{ color: 'red' }}>Ajouter Fax</span> || '' },
        { label: 'Email', value: PharmacienData?.EmailActivite || '' },
        { label: 'Mobile', value: PharmacienData?.TelMobileActivite || '' },
        { label: 'Fixe', value: PharmacienData?.TelFixeActivite || '' },
        // { label: 'Date operation', value: formatDateReader(PharmacienData?.DateOperation) || '' },
        // { label: 'Courrier maison', value: PharmacienData?.CourrierMaison ? 'Oui' : "Non" || '' },
    ];
    const handleUpdatePharmacien = async () => {
        const {
            NumeroInscription,
            CodeActivite,
            CodeConseilRegional,
            AdresseActivite, AdresseActiviteArabe,
            VilleAdresseActivite, VilleAdresseActiviteArabe,
            GouvernoratActivite, GouvernoratActiviteArabe,
            CodePostalAdresseActivite,
            TelFixeActivite,
            TelMobileActivite, FaxActivite, EmailActivite
        } = Data;
        const extractedData = {
            NumeroInscription,
            CodeActivite,
            CodeConseilRegional,
            AdresseActivite, AdresseActiviteArabe,
            VilleAdresseActivite, VilleAdresseActiviteArabe,
            GouvernoratActivite, GouvernoratActiviteArabe,
            CodePostalAdresseActivite,
            TelFixeActivite, TelMobileActivite, FaxActivite, EmailActivite
        };
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/addDemandeNewActivite`, extractedData)
            .then((res) => {
                successToast(res.data.message)
            })
            .catch((err) => (
                errorToast("Something went wrong")
            ))
    };

    return (
        <div className='Activite-container'>
            {
                disabled ? <div className='information-list'>
                    {informationList.map((item, index) => (
                        <div className='information-item' key={index}>
                            <span className='list-text' style={{ textAlign: "left" }}>{item.label}:</span>
                            <span className='information-value'>{item.value}</span>
                        </div>
                    ))}
                </div>
                    :
                    <>
                        <div className='first-inpt-holder-Activite'>
                            <span className='list-text'>Libelle activité</span>
                            <select
                                disabled={PharmacienData?.Activite?.LibelleActivite ? true : false}
                                value={PharmacienData?.Activite?.LibelleActivite}
                                onChange={(e) => { setData({ ...Data, LibelleActivite: e.target.value }) }}
                                className='Information_personnelle_first_input'
                            >
                                <option value="">Select an activity</option>
                                {activiteList.map((activity, index) => (
                                    <option key={index} value={activity.LibelleActivite}>
                                        {activity.LibelleActivite}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='first-inpt-holder-Activite' >
                            <span className='list-text'>Date debut activité</span>
                            <input disabled={PharmacienData?.DateDebutActivite ? true : false} type='date' value={formatDateReader(PharmacienData?.DateDebutActivite || '')} onChange={(e) => { setData({ ...Data, DateDebutActivite: e.target.value }) }}
                                className='Information_personnelle_first_input' />
                        </div>
                        <ToastContainer />
                        <div className='inputs-holders-Activite'>
                            <div className='inner-input-holder'>
                                <span className='list-text'>Adresse</span>
                                <input
                                    disabled={PharmacienData?.AdresseActivite ? true : false}

                                    placeholder="Aa"
                                    value={selectedCategory || PharmacienData?.AdresseActivite || ''}
                                    onChange={(e) => { setData({ ...Data, AdresseActivite: e.target.value }) }}

                                    className='Information_personnelle_input'

                                />

                            </div>
                            <div className='inner-input-holder-activite'>
                                <span className='list-text'>العنوان</span>
                                <input
                                    disabled={PharmacienData?.AdresseActiviteArabe ? true : false}
                                    style={{ textAlign: 'end' }}
                                    placeholder="تونس"
                                    value={selectedCategoryArabe || PharmacienData?.AdresseActiviteArabe || ''}
                                    onChange={(e) => { setData({ ...Data, AdresseActiviteArabe: e.target.value }) }}

                                    className='Information_personnelle_input'
                                />
                            </div>
                        </div>
                        <div className='inputs-holders-Activite'>
                            <div className='inner-input-holder'>

                                <span className='list-text'>Ville</span>
                                <input disabled={PharmacienData?.VilleAdresseActivite ? true : false} placeholder='Aa' value={PharmacienData?.VilleAdresseActivite || ''} className='Information_personnelle_input'
                                    onChange={(e) => { setData({ ...Data, VilleAdresseActivite: e.target.value }) }}

                                />
                            </div>
                            <div className='inner-input-holder-activite'>

                                <span className='list-text'>المدينة</span>
                                <input placeholder='اج' disabled={PharmacienData?.VilleAdresseActiviteArabe ? true : false} value={PharmacienData?.VilleAdresseActiviteArabe || ''} style={{ textAlign: 'end' }} className='Information_personnelle_input'
                                    onChange={(e) => { setData({ ...Data, VilleAdresseActiviteArabe: e.target.value }) }}

                                />
                            </div>
                        </div>
                        <div className='inputs-holders-Activite'>
                            <div className='inner-input-holder'>

                                <span className='list-text'>Gouvernorat</span>
                                <input disabled={PharmacienData?.GouvernoratActivite ? true : false} placeholder='Aa' value={PharmacienData?.GouvernoratActivite || ''} className='Information_personnelle_input'
                                    onChange={(e) => { setData({ ...Data, GouvernoratActivite: e.target.value }) }}

                                />
                            </div>
                            <div className='inner-input-holder-activite'>

                                <span className='list-text'>الولاية</span>
                                <input placeholder='اج' disabled={PharmacienData?.GouvernoratActiviteArabe ? true : false} value={PharmacienData?.GouvernoratActiviteArabe || ''} style={{ textAlign: 'end' }} className='Information_personnelle_input'
                                    onChange={(e) => { setData({ ...Data, GouvernoratActiviteArabe: e.target.value }) }}

                                />
                            </div>
                        </div>
                        <div className='inputs-holders-Activite'>
                            <div className='inner-input-holder'>

                                <span className='list-text'>Fax</span>
                                <input onChange={(e) => { setData({ ...Data, FaxActivite: e.target.value }) }} disabled={PharmacienData?.FaxActivite ? true : false} placeholder='Aa' value={Data?.FaxActivite || ''} className='Information_personnelle_input'

                                />
                            </div>
                            <div className='inner-input-holder-activite'>

                                <span className='list-text' style={{ textAlign: 'left', width: "70%" }}>Email</span>
                                <input disabled={PharmacienData?.EmailActivite ? true : false} placeholder='Aa' value={Data?.EmailActivite || ''} className='Information_personnelle_input'
                                    onChange={(e) => { setData({ ...Data, EmailActivite: e.target.value }) }}

                                />
                            </div>
                        </div>
                        <div className='inputs-holders-Activite'>
                            <div className='inner-input-holder'>

                                <span className='list-text'>Mobile</span>
                                <input disabled={PharmacienData?.TelMobileActivite ? true : false} placeholder='Aa' value={PharmacienData?.TelMobileActivite || ''} className='Information_personnelle_input'
                                    onChange={(e) => { setData({ ...Data, TelMobileActivite: e.target.value }) }}

                                />
                            </div>
                            <div className='inner-input-holder-activite'>

                                <span className='list-text' style={{ textAlign: 'left', width: "70%" }}>Fixe</span>
                                <input disabled={PharmacienData?.TelFixeActivite ? true : false} placeholder='Aa' value={PharmacienData?.TelFixeActivite || ''} className='Information_personnelle_input'
                                    onChange={(e) => { setData({ ...Data, TelFixeActivite: e.target.value }) }}

                                />
                            </div>
                        </div>

                    </>
            }

            {/* {
                disabled ?
                    <button className='button-Acitivite' onClick={() => setdisabled(!disabled)}>Demande de changement d'activité</button> :
                    <button className='button-Acitivite' onClick={handleUpdatePharmacien}>Sauvegarder les modifications</button>
            } */}
        </div>
    )
}
