const Frensh_locals = [
    [
        "Tunis Ville",
        "Le Bardo",
        "EL kram",
        "La Goulette",
        "Carthage",
        "Sidi Bou Said",
        "La Marsa",
        "Sidi Hassine"
    ],
    [
        "Ariana",
        "Soukra",
        "Raoued",
        "Kalaat El Andalos",
        "Sidi Thabet",
        "Ettadhamen",
        "El Mnihla"
    ],
    [
        "Ben Arous",
        "El Mourouj",
        "Hammam Lif",
        "Hammam Chott",
        "Bou Mhel El Bassatine",
        "Ez-Zahra",
        "Rades",
        "Megrine",
        "Mhamedia",
        "Mornag",
        "Khelidia",
        "Fouchana",
        "Naassen",
    ],
    [
        "Mannouba",
        "Denden",
        "Douar Hicher",
        "Oued Ellil",
        "Mornaguia",
        "Borj El Amri",
        "Djedaida",
        "Tebourba",
        "El Battan",
        "El Bassatine"
    ],
    [
        "Nabeul",
        "Dar Chaabane El Fehri",
        "Beni Khiar",
        "El Mâamoura",
        "Somaâ",
        "Korba",
        "Tazarka",
        "Menzel Temime",
        "Menzel Horr",
        "El Mida",
        "Kelibia",
        "Azmour",
        "Hammam El Guezaz",
        "Dar Allouch",
        "El Haouaria",
        "Takelsa",
        "Soliman",
        "Korbous",
        "Menzel Bou Zelfa",
        "Beni Khalled",
        "Zaouiet Djedidi",
        "Grombalia",
        "Bou Argoub",
        "Hammamet",
        "Fondek Ejedid - Es Selten",
        "Tazegrane Boukrim Zaouiet Mgaiez",
        "Sidi Jedidi",
        "Echerifet Boucharray"
    ],
    [
        "Zaghouan",
        "Ez-Zeriba",
        "Bir Mchergua",
        "Jebel Oust",
        "El Fahs",
        "En-Nadhour",
        "Saouaf",
        "El Amaiem"
    ],
    [
        "Bizerte",
        "Sedjnane",
        "Mateur",
        "Menzel Bourguiba",
        "Tinja",
        "Ghar El Meleh",
        "Ousja",
        "Menzel Djemil",
        "Menzel Abderrahmen",
        "El Alia",
        "Ras El Djebel",
        "Metline",
        "Rafraf",
        "Utique",
        "Joumine",
        "Ghzala",
        "El Hchachna"
    ],
    [
        "Béja",
        "El Mâagoula",
        "Zahret Medien",
        "Nefza",
        "Teboursouk",
        "Testour",
        "Goubellat",
        "Medjez El Bab",
        "Tibar",
        "Ouechtata - El Jamila",
        "Sidi Ismail",
        "Slouguia"
    ],
    [
        "Jendouba",
        "Bou Salem",
        "Tabarka",
        "Ain Draham",
        "Fernana",
        "Beni Mtir",
        "Ghardimaou",
        "Oued Meliz",
        "Balta -Bouaouene",
        "Souk Es-Sabet",
        "El Golaa - El Maaden -Forgassen",
        "Jaouaouada",
        "Tabarka Nouvelle",
        "Hammem Bourguiba"
    ],
    [
        "Le Kef",
        "Nebeur",
        "Touiref",
        "Sakiet Sidi Youssef",
        "Tajerouine",
        "Menzel Salem",
        "Kalâat Snan",
        "Kalâat Khasba",
        "Djerissa",
        "El Ksour",
        "Dahmani",
        "Es-Sers",
        "Zâafrane - Dyr El Kef",
        "Bahra",
        "El Marja"
    ],
    [
        "Siliana",
        "Bou Arada",
        "Gaâfour",
        "El Krib",
        "Sidi Bourouis",
        "Makthar",
        "Er-Rouhia",
        "Kesra",
        "Bargou",
        "El Aroussa",
        "Sidi Morched",
        "El Hababsa"
    ],
    [
        "Sousse",
        "Ksibet Sousse et Thrayet",
        "Ezzouhour",
        "Zaouiet Sousse",
        "Hammam Sousse",
        "Akouda",
        "Kalâa Kébira",
        "Sidi Bou Ali",
        "Hergla",
        "Enfidha",
        "Bouficha",
        "Sidi El Heni",
        "MSaken",
        "Kalâa Seghira",
        "Messâdine",
        "Kandar",
        "Gerimet Hicher",
        "Chott Meriam"
    ],
    [
        "Monastir",
        "Khenis",
        "Ouerdanine",
        "Sahline Môotmar",
        "Sidi Ameur Mesjed Issa",
        "Zermadine",
        "Beni Hassen",
        "Ghnada",
        "Djammel",
        "Menzel Kamel",
        "Zouiet Kontoch",
        "Bembla Manara",
        "Menzel En-Nour",
        "EL Masdour",
        "Moknine",
        "Sidi Bennour",
        "Menzel Farsi",
        "Amiret El Fhoul",
        "Amiret Touazra",
        "Amirat Hojjaj",
        "Cherahil",
        "Bekalta",
        "Teboulba",
        "Ksar Helal",
        "Ksibt El Mediouni",
        "Benen Bodher",
        "Touza",
        "Sayada",
        "Lamta",
        "Bou-Hajar",
        "Menzel Hayet"
    ],
    [
        "Mahdia",
        "Réjiche",
        "Bou Merdes",
        "Ouled Chamekh",
        "Chorbane",
        "Hebira",
        "Essouassi",
        "El Djem",
        "Kerker",
        "Chebba",
        "Melloulech",
        "Sidi Alouane",
        "Ksour Essef",
        "El Bradâa",
        "Sidi Zid-Ouled Moulahem",
        "El Hekaima",
        "Talalsa",
        "Zalba"
    ],
    [
        "Sfax",
        "Sakiet Ezzit",
        "Chihia",
        "Sakiet Eddaier",
        "Gremda",
        "El Ain",
        "Tina",
        "Agareb",
        "Djebeniana",
        "El Hencha",
        "Menzel Chaker",
        "El Gheraiba",
        "Bir Ali Ben Khelifa",
        "Skhira",
        "Mahrès",
        "Kerkennah",
        "El Amra",
        "El Aouabed-Khazanet",
        "Bir Ali Nord",
        "El Hajeb",
        "Hzag-Ellouza",
        "Ennour - Sfax",
        "En-Nacer"
    ],
    [
        "Kairouan",
        "Echbika",
        "Sbikha",
        "El Oueslatia",
        "Ain Djeloula",
        "Haffouz",
        "El Alâa",
        "Hajeb El Ayoun",
        "Nasrallah",
        "Menzel Mehiri",
        "Echrarda",
        "Bouhajla",
        "Sisseb-Ed-Dheriaat",
        "Jehina",
        "Rakada",
        "Abida",
        "Chwayhia",
        "El Aîn El Beidha",
        "Chaouachi"
    ],
    [
        "Kasserine",
        "Sbeitla",
        "Sbiba",
        "Djedeliane",
        "Thala",
        "Hidra",
        "Foussana",
        "Feriana",
        "Telept",
        "Majel Bel Abbes",
        "El Ayoun",
        "Hassi El Farid",
        "Ez-Zouhour",
        "En-Nour - Kasserine",
        "Ech-Charayaa- Machrek Ech-Chams",
        "Er-Rakhmet",
        "Ain El Khemaissia",
        "Khmouda",
        "Bouzguam"
    ],
    [
        "Sidi Bouzid",
        "Djelma",
        "Cebalet",
        "Bir El Hafey",
        "Sidi Ali Ben Aoun",
        "Menzel Bouzaine",
        "Meknassy",
        "Mezzouna",
        "Regueb",
        "Ouled Haffouz",
        "Essaaida",
        "Souk Jedid",
        "El Assouada",
        "Faiedh Bennour",
        "Batten El Ghézel",
        "Rahal",
        "El Mansoura"
    ],
    [
        "Gabes",
        "Chenini Nahal",
        "Ghanouch",
        "El Metouia",
        "Ouedhref",
        "El Hamma",
        "Matmata",
        "Nouvelle Matmata",
        "Mareth",
        "Ezzarat",
        "Menzel El Habib",
        "Bou-chemma",
        "Teboulbou",
        "Hamma Ouest",
        "Kattana",
        "Dkhilet Toujane"
    ],
    [
        "Medenine",
        "Beni Khedache",
        "Ben Guerdane",
        "Zarzis",
        "Djerba Houmet Souk",
        "Djerba Midoun",
        "Djerba Ajim",
        "Sidi Makhlouf",
        "Zarzis Nord",
        "Bou-Ghrara"
    ],
    [
        "Tataouine",
        "Bir Lahmar",
        "Ghomrassen",
        "Dhehiba",
        "Remada",
        "Smar",
        "Tataouine Sud"
    ],
    [
        "Gafsa",
        "El Ksar",
        "Moularès",
        "Redeyef",
        "Metlaoui",
        "Mdhila",
        "Guetar",
        "Sned",
        "Belkhir",
        "Sidi Aich",
        "Lala",
        "Sidi Boubaker",
        "Zanouch"
    ],
    [
        "Tozeur",
        "De guech",
        "Hamet Jerid",
        "Nefta",
        "Tameghza",
        "Hazoua"
    ],
    [
        "Kebili",
        "Jemna",
        "Douz",
        "El Golâa",
        "Souk El Ahad",
        "Faouar",
        "Rejim Maatoug",
        "Bechelli-Jersine-El Blidete",
        "Bèchri-Fatnassa"
    ]]
export default Frensh_locals